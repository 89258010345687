import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import TripView from './TripView';
import { getTripFullDetailById } from 'services/tripService';
import 'components/css/post.scss';
import './tripView.scss';
import requireAuth from 'components/requireAuth';

/** @Desc
 * Root component for viewing trip
 */
const TripViewPage = ({ history, match }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [trip, setTrip] = useState(null);

  useEffect(() => {
    getTripFullDetailById(match.params.id)
      .then(({ errors, data: { data } }) => {
        !errors ? setTrip(data.getTripById) : console.error(errors);
        setRenderApp(true);
      })
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    <div style={{ backgroundColor: '#FFF', paddingTop: '2rem' }}>
      <div className="container-md">
        <button
          className="btn-chromeless"
          onClick={() => history.goBack()}
          style={{ marginBottom: '10px', display: 'block' }}
        >
          <i className="fas fa-chevron-left"></i> Back
        </button>
        <hr />
        <TripView trip={trip} />
      </div>
    </div>
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(TripViewPage);
