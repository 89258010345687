import axios from 'services/axios';

export function getCountries() {
  return axios.post('/graphql', {
    query: `
      query{
        getCountries{
          id,
          name,
        }
      }
    `,
  });
}

export function getAllPlaces() {
  return axios.post('/graphql', {
    query: `
      query{
        getAllPlaces{
          id,
          published,
          enabled,
          name,
          image_url,
          place_type_id,
        }
      }
    `,
  });
}

export function addPlace(formProps) {
  return axios.post('/graphql', {
    query: `
      mutation AddPlace($enabled: Boolean!, $name: String!, $caption: String!, $description: String!, $place_id: String, $place_type_id: String!) {
        addPlace(enabled: $enabled, name: $name, caption: $caption, description: $description, place_id: $place_id, place_type_id: $place_type_id) {
          id
        }
      }
    `,
    variables: formProps,
  });
}

export function editPlace(formProps) {
  return axios.post('/graphql', {
    query: `
      mutation EditPlace($id: String!, $enabled: Boolean!, $name: String!, $caption: String!, $description: String!, $place_id: String, $place_type_id: String!) {
        editPlace(id: $id, enabled: $enabled, name: $name, caption: $caption, description: $description, place_id: $place_id, place_type_id: $place_type_id) {
          id
        }
      }
    `,
    variables: formProps,
  });
}

export function publishPlace(id) {
  return axios.post('/graphql', {
    query: `
      mutation PublishPlace($id: String!) {
        publishPlace(id: $id)
      }
    `,
    variables: { id },
  });
}

export function getPlaceById(id) {
  return axios.post('/graphql', {
    query: `
      query GetPlaceByID($id: String!) {
        getPlaceById(id: $id) {
          id,
          name,
          caption,
          description,
          image_url,
          place_id,
          published,
          enabled,
          place_type_id
        }
      }
    `,
    variables: { id },
  });
}

export function uploadPlaceImage(id, formData) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };

  return axios.post(`/api/place/${id}/image-upload`, formData, { headers });
}

export function deletePlaceImage(id) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };

  return axios.delete(`/api/place/${id}/image-remove/`, { headers });
}
