import React, { Component } from 'react';
import Login from './Login';
import BookingListPage from './booking/BookingListPage';

import { connect } from 'react-redux';

class StartPage extends Component {
  __renderPage() {
    return this.props.auth ? (
      <div>
        <BookingListPage {...this.props} />
      </div>
    ) : (
      <Login />
    );
  }
  render() {
    return <div>{this.__renderPage()}</div>;
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, null)(StartPage);
