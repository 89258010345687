import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'services/utils';

export default ({ bookings, id }) => {
  const [searchTerm, setSearchTerm] = useState(id ? id : '');

  useEffect(() => {
    setSearchTerm(id ? id : '');
  }, [id]);

  return (
    <div>
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Bookings</h1>
        </div>
        {/*
            <div className="head-right">
          <Link to="/bookings/new" className="btn btn-dark btn-sm">
            Create new booking
          </Link>
        </div>
            */}
      </div>

      <div className="content-card ">
        <div
          style={{
            marginBottom: '15px',
          }}
        >
          <form method="GET">
            <input
              name="booking_id"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control search-input"
              placeholder="Search using booking code"
              autoComplete="off"
            />
          </form>
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Code</th>
                <th style={{ whiteSpace: 'nowrap' }}>Duration date</th>
                <th style={{ whiteSpace: 'nowrap' }}>Booked at</th>
                <th style={{ whiteSpace: 'nowrap' }}>Guests</th>
                <th style={{ whiteSpace: 'nowrap' }}>Price paid</th>
                <th style={{ whiteSpace: 'nowrap' }}>Trip</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking.id}>
                  <td style={{ padding: '1rem 0.75rem' }}>
                    <Link className="link" to={`/bookings/${booking.id}`}>
                      {booking.id.toUpperCase()}
                    </Link>
                  </td>
                  <td style={{ padding: '1rem' }}>
                    {formatDate(new Date(booking.start_date))} -{' '}
                    {formatDate(new Date(booking.end_date))}
                  </td>
                  <td style={{ padding: '1rem' }}>
                    {formatDate(new Date(booking.created_at))}
                  </td>
                  <td style={{ padding: '1rem' }}>{booking.guests}</td>
                  <td style={{ padding: '1rem' }}>USD ${booking.paid_price}</td>
                  <td style={{ padding: '1rem' }}>
                    <Link
                      to={`/trips/view/${booking.trip.id}`}
                      className="link"
                    >
                      {booking.trip.name}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
