import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import App from 'components/App';
import StartPage from 'components/site/StartPage';
import PlaceListPage from 'components/site/place/PlaceListPage';
import PostPlacePage from 'components/site/place/post/basic/PostPlacePage';
import PostPlaceImagePage from 'components/site/place/post/image/PostPlaceImagePage';

import TripListPage from 'components/site/trip/TripListPage';
import PostTripPage from 'components/site/trip/post/basic/PostTripPage';
import PostTripDetailPage from 'components/site/trip/post/detail/PostTripDetailPage';
import PostTripPlacePage from 'components/site/trip/post/place/PostTripPlacePage';
import PostTripTypePage from 'components/site/trip/post/theme/PostTripTypePage';
import PostTripTagPage from 'components/site/trip/post/tag/PostTripTagPage';
import PostTripItineraryPage from 'components/site/trip/post/itinerary/PostTripItineraryPage';
import PostTripImagePage from 'components/site/trip/post/images/PostTripImagePage';
import TripViewPage from 'components/site/trip/TripViewPage';
import BookingViewPage from 'components/site/booking/view/BookingViewPage';
import CustomerListPage from 'components/site/customer/CustomerListPage';
import NotFoundPage from 'components/site/misc/NotFoundPage';
import HelpPage from 'components/site/misc/HelpPage';
import FlushPage from 'components/site/misc/FlushPage';

import reducers from './reducers';
import * as serviceWorker from './serviceWorker';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <Switch>
          <Route path="/" component={StartPage} exact />
          <Route path="/places" component={PlaceListPage} exact />
          <Route path="/places/new" component={PostPlacePage} exact />
          <Route
            path="/places/edit/:id"
            component={(props) => <PostPlacePage {...props} isEdit={true} />}
            exact
          />
          <Route
            path="/places/edit/:id/image"
            component={(props) => (
              <PostPlaceImagePage {...props} isEdit={true} />
            )}
            exact
          />

          <Route path="/trips" component={TripListPage} exact />
          <Route path="/trips/new" component={PostTripPage} exact />
          <Route
            path="/trips/edit/:id"
            component={(props) => <PostTripPage {...props} isEdit={true} />}
            exact
          />
          <Route
            path="/trips/edit/:id/details"
            component={(props) => (
              <PostTripDetailPage {...props} isEdit={true} />
            )}
            exact
          />
          <Route
            path="/trips/edit/:id/places"
            component={(props) => (
              <PostTripPlacePage {...props} isEdit={true} />
            )}
            exact
          />
          <Route
            path="/trips/edit/:id/types"
            component={(props) => <PostTripTypePage {...props} isEdit={true} />}
            exact
          />
          <Route
            path="/trips/edit/:id/tags"
            component={(props) => <PostTripTagPage {...props} isEdit={true} />}
            exact
          />
          <Route
            path="/trips/edit/:id/itinerary"
            component={(props) => (
              <PostTripItineraryPage {...props} isEdit={true} />
            )}
            exact
          />
          <Route
            path="/trips/edit/:id/photos"
            component={(props) => (
              <PostTripImagePage {...props} isEdit={true} />
            )}
            exact
          />
          <Route path="/trips/view/:id" component={TripViewPage} exact />

          <Route path="/bookings/:id" component={BookingViewPage} exact />
          <Route path="/customers" component={CustomerListPage} exact />

          <Route path="/analytics" component={PlaceListPage} />
          <Route path="/help" component={HelpPage} exact />
          <Route path="/flush" component={FlushPage} exact />

          <Route path="/error" component={NotFoundPage} exact />
          <Route component={NotFoundPage} />
        </Switch>
      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
