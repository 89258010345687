import React, { useState, useReducer } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextAreaAutoSize from 'components/site/helpers/TextAreaAutoSize';
import { Helmet } from 'react-helmet';

export default ({
  actions: { handleSubmit, updateForm },
  states: { formProps, submitting, isEdit },
}) => {
  const [validator] = useState(
    new SimpleReactValidator({
      locale: 'en',
      element: (message) => (
        <div className="validation-message text-danger">{message}</div>
      ),
      validators: {
        duration: {
          // name the rule
          message: 'The format must be number followed by hour/s or day/s',
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^\d+\s(hours?|days?)$/i) &&
              params.indexOf(val) === -1
            );
          },
          required: true, // optional
        },
      },
    })
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function onSubmit() {
    if (validator.allValid()) return handleSubmit();
    validator.showMessages();
    forceUpdate();
  }

  const {
    name,
    enabled,
    featured,
    price,
    duration,
    activity_level,
    max_altitude,
    best_season,
    description,
  } = formProps;

  validator.purgeFields();
  return (
    <div className="container-md postContent">
      <Helmet>
        <title>
          {isEdit ? 'Update experience' : 'Add experience'} | Visit Nepal
          Dashboard
        </title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">{isEdit ? 'Update' : 'Add'} experience</h1>
        <p>
          Create tours, adventures, activities and all sort of experiences for
          your site. Also make sure to fill up all the fields correctly to make
          customer easy to find your experience. Also mind the grammer.
        </p>
        <hr />

        <div className="content-card ">
          <div className="form-group">
            <label className="label">
              <strong>Enable</strong>
            </label>
            <label className="switch">
              <input
                type="checkbox"
                name="enabled"
                checked={enabled}
                onChange={updateForm}
              />
              <span className="slider round"></span>
            </label>
            <div>
              <small
                style={{ marginTop: '8px' }}
                className="form-text text-muted"
              >
                By enabling, this trip will be visible in main site.
              </small>
            </div>
          </div>

          <div className="form-group">
            <label className="label">
              <strong>Feature this trip</strong>
            </label>
            <label className="switch">
              <input
                type="checkbox"
                name="featured"
                checked={featured}
                onChange={updateForm}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="form-group">
            <RenderField
              field="input"
              label="Trip title*"
              name="name"
              maxLength="100"
              value={name}
              placeholder="Himalayan trek with views of Everest"
              onChange={updateForm}
            />
            {validator.message(
              'name',
              name,
              'required|max:100|alpha_num_space'
            )}
          </div>

          <div className="form-group">
            <label className="label">Price*</label>
            <div className="input-group">
              <div className="input-group-prepend">USD/person</div>
              <input
                onChange={updateForm}
                autoComplete="off"
                value={price}
                type="number"
                name="price"
                className="form-control"
              />
            </div>
            {validator.message('price', price, 'required|numeric|min:10,num')}
          </div>

          <div className="form-group">
            <label className="label">Trip overview*</label>
            <TextAreaAutoSize
              name="description"
              value={description}
              maxLength="2100"
              placeholder="What will we be doing?"
              onChange={updateForm}
              className="form-control"
            />
            <div style={{ marginTop: '4px' }}>{description.length} / 2100</div>
            {validator.message('description', description, 'required|max:2100')}
          </div>

          <div className="form-group">
            <RenderField
              field="input"
              label="Duration*"
              name="duration"
              value={duration}
              placeholder="e.g. 4 days or 9 hours"
              onChange={updateForm}
            />
            {validator.message('duration', duration, 'required|duration')}
          </div>

          <div className="form-group">
            <RenderField
              field="input"
              label="Max altitude"
              name="max_altitude"
              value={max_altitude}
              placeholder="e.g. 4400 m."
              onChange={updateForm}
            />
            {validator.message('Max altitude', max_altitude, 'max:30')}
          </div>

          <div className="form-group">
            <RenderField
              field="input"
              label="Best season"
              name="best_season"
              maxLength="150"
              value={best_season}
              placeholder="e.g. Spring - Autumn"
              onChange={updateForm}
            />
            {validator.message('Best season', best_season, 'max:150')}
          </div>

          <div className="form-group">
            <RenderActivityLevelField
              name="activity_level"
              value={activity_level}
              onChange={updateForm}
              label="Select activity level*"
              options={[
                'Easy',
                'Moderate',
                'Moderate +',
                'Strenuous',
                'Strenuous +',
              ]}
            />
          </div>

          <div className="bottom-panel d-flex">
            <div />
            {submitting ? (
              <div
                className="spi"
                style={{ margin: '12px 0', marginRight: '0' }}
              ></div>
            ) : (
              <button className="btn btn-dark" onClick={onSubmit}>
                {isEdit ? (
                  <>
                    Save <i className="fas fa-chevron-right"></i>
                  </>
                ) : (
                  'Next'
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
/*
 helper component for rendering input field
*/
function RenderField(props) {
  return (
    <div>
      <label className="label">{props.label}</label>
      <props.field autoComplete="off" className="form-control" {...props} />
    </div>
  );
}

function RenderActivityLevelField({ value, label, name, onChange, options }) {
  return (
    <div>
      <label className="label" htmlFor={`id${name}`}>
        {label} &nbsp;
        <a
          href="https://lalman.com/trek-manual"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          <i className="far fa-question-circle"></i>
        </a>
      </label>
      <select
        className="form-control"
        name={name}
        value={value}
        onChange={onChange}
        id={`id${name}`}
      >
        {options.map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  );
}
