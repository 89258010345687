import React, { useState, useEffect } from 'react';
import DashboardWrapper from 'components/site/commons/DashboardWrapper';
import { Link } from 'react-router-dom';
import requireAuth from 'components/requireAuth';
import SpinLoader from 'components/site/helpers/SpinLoader';
import { getAllTrips } from 'services/tripService';
import { getImageSource } from 'services/imageService';
import { Helmet } from 'react-helmet';

const TripListPage = () => {
  const [trips, setTrips] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAllTrips().then(({ errors, data: { data } }) => {
      !errors ? setTrips(data.getAllTrips) : console.error(errors);
    });
  }, []);

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <Helmet>
        <title>Trips | Visit Nepal Dashboard</title>
      </Helmet>
      {trips ? (
        trips.length > 0 ? (
          <RenderTrips
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            trips={trips}
          />
        ) : (
          <div>
            <h1 className="page-title">Experiences</h1>
            <div style={{ margin: '1.5rem 0 2.5rem' }}>
              <h2>No experiences found as of yet!</h2>
              <p className="text-muted">
                Add your own unique experiences. Create adventures, trips, tours
                and much more. Let's go!
              </p>
            </div>
            <Link to="/trips/new" className="btn btn-dark">
              Add experience
            </Link>
          </div>
        )
      ) : (
        <SpinLoader />
      )}
    </>
  );
};
export default requireAuth(DashboardWrapper(TripListPage));

function RenderTrips({ trips, searchTerm, handleSearch }) {
  return (
    <div>
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Experiences</h1>
        </div>
        <div className="head-right">
          <Link to="/trips/new" className="btn btn-dark btn-sm">
            Add new experience
          </Link>
        </div>
      </div>

      <div className="content-card ">
        <div
          style={{
            marginBottom: '15px',
          }}
        >
          <input
            value={searchTerm}
            onChange={handleSearch}
            className="form-control search-input"
            placeholder="Filter using name"
          />
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th />
                <th>Status</th>
                <th>Enabled</th>
                <th>Featured</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {trips
                .filter((trip) =>
                  trip.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((t) => (
                  <tr key={t.id}>
                    <td style={{ width: '70px', height: '70px' }}>
                      <Link to={`/trips/edit/${t.id}/photos`}>
                        <img
                          className="rounded"
                          style={{
                            objectFit: 'cover',
                            width: '70px',
                            height: '50px',
                          }}
                          src={
                            t.trip_images.length > 0
                              ? getImageSource(
                                  t.trip_images[0].image_url,
                                  190,
                                  190
                                )
                              : getImageSource(null)
                          }
                          alt={t.name}
                        />
                      </Link>
                    </td>
                    <td>
                      <Link className="link" to={`/trips/view/${t.id}`}>
                        {t.name}
                      </Link>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          t.published ? 'badge-success' : 'badge-warning'
                        }`}
                      >
                        {t.published ? 'Published' : 'In progress'}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          t.enabled ? 'badge-success' : 'badge-danger'
                        }`}
                      >
                        {t.enabled ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          t.featured ? 'badge-success' : 'badge-default'
                        }`}
                      >
                        {t.featured ? 'Featured' : 'Not featured'}
                      </span>
                    </td>
                    <td>
                      <Link
                        className="btn btn-sm btn-default"
                        to={`/trips/edit/${t.id}`}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
