import React, { useState, useEffect, useCallback } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostTripItineraryAction from './PostTripItineraryAction';
import { getTripIteneraries } from 'services/tripService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for adding/editing/deleting trip itineraries
 */
const PostTripTagPage = ({ match, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [tripItineraries, setTripItineraries] = useState([]);

  const fetchItineraries = useCallback(() => {
    setRenderApp(false);
    getTripIteneraries(match.params.id)
      .then(({ errors, data: { data } }) => {
        !errors
          ? setTripItineraries(data.getTripIteneraries)
          : console.error(errors);
        setRenderApp(true);
      })
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchItineraries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    !tripItineraries ? (
      <OnPageNotFound />
    ) : (
      <PostTripItineraryAction
        tripItineraries={tripItineraries}
        reFetchItineraries={fetchItineraries}
        id={match.params.id}
      />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripTagPage));
