import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostTripTagAction from './PostTripTagAction';
import { getTripTags } from 'services/tripService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for adding/deleting trip tags/keywords
 */
const PostTripTagPage = ({ match, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [tripTags, setTripTags] = useState([]);

  useEffect(() => {
    getTripTags(match.params.id)
      .then(({ errors, data: { data } }) => {
        !errors ? setTripTags(data.getTripTags) : console.error(errors);
        setRenderApp(true);
      })
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    !tripTags ? (
      <OnPageNotFound />
    ) : (
      <PostTripTagAction
        history={history}
        previousTripTags={tripTags}
        id={match.params.id}
      />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripTagPage));
