import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostTripDetailAction from './PostTripDetailAction';
import { getTripById } from 'services/tripService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for posting trip details i.e. includes, to_bring, to_know
 */
const PostTripDetailPage = ({ match, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [trip, setTrip] = useState(null);

  useEffect(() => {
    getTripById(match.params.id)
      .then(({ errors, data: { data } }) => {
        !errors ? setTrip(data.getTripById) : console.error(errors);
        setRenderApp(true);
      })
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    !trip ? (
      <OnPageNotFound />
    ) : (
      <PostTripDetailAction history={history} previousTrip={trip} />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripDetailPage));
