import { AUTH_USER } from './types';
import axios from 'services/axios';

export const signIn = (formProps, callback) => async (dispatch) => {
  try {
    const res = await axios.post('/graphql', {
      query: `
        query Login($email: String!, $password: String!) {
          login(email: $email, password: $password)
        }
      `,
      variables: formProps,
    });

    const { errors, data } = res.data;
    if (errors) {
      throw new Error(errors[0].message);
    } else {
      /*
       * Save token to local storage and reload
       */
      localStorage.setItem('token', data.login);
      window.location.reload();
    }
  } catch (err) {
    callback(err.message);
  }
};

export const getUser = (callback) => async (dispatch) => {
  try {
    const res = await axios.post('/graphql', {
      query: `
        query{
          user{
            email,
            role
          }
        }
      `,
    });
    const { errors, data } = res.data;
    if (errors) {
      throw new Error(errors[0].message);
    } else {
      dispatch({ type: AUTH_USER, payload: data.user });
    }
  } catch (err) {
    // Remove token unless its a network connection error
    if (err.message !== 'Network Error') localStorage.removeItem('token');
  } finally {
    callback();
  }
};

export const signOut = () => (dispatch) => {
  window.location.href = '/';
  localStorage.removeItem('token');
};
