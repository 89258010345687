import React from 'react';
import { withRouter } from 'react-router-dom';
import Footer from 'components/site/commons/Footer';
import { Helmet } from 'react-helmet';

export default () => (
  <div className="container-sm" style={{ marginTop: '4rem' }}>
    <Helmet>
      <title>404 page not found | Visit Nepal Dashboard</title>
    </Helmet>
    <h1>Oops something went wrong!</h1>
    <p style={{ margin: '1rem 0px 1.5rem' }}>
      We're really sorry either the page your looking for doesn't exist or you
      don't have sufficient permission.
    </p>
    <a href="/" className="btn btn-dark">
      Return Home
    </a>
    <br />
    <br />
    <br />
    <Footer />
  </div>
);

export const OnPageNotFound = withRouter(({ history }) => {
  history.push('/error');
  return null;
});
