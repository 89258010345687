import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostActions from './PostActions';
import requireAuth from 'components/requireAuth';
import { getCountries, getPlaceById } from 'services/placeService';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for posting place i.e. add/edit place
 @Parms
 isEdit - True if component is being called for editing existing place
 match - passed by react-router-dom
 */
const PostPlacePage = ({ isEdit, match }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [countries, setCountries] = useState([]);
  const [editFormProps, setEditFormProps] = useState(null);

  useEffect(() => {
    const promises = [];

    const promise1 = getCountries().then(({ errors, data: { data } }) => {
      !errors ? setCountries(data.getCountries) : console.error(errors);
    });
    promises.push(promise1);

    if (isEdit) {
      const promise2 = getPlaceById(match.params.id).then(
        ({ errors, data: { data } }) => {
          !errors ? setEditFormProps(data.getPlaceById) : console.error(errors);
        }
      );
      promises.push(promise2);
    }

    Promise.all(promises).then(() => {
      setRenderApp(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    isEdit && !editFormProps ? (
      <OnPageNotFound />
    ) : (
      <PostActions countries={countries} editFormProps={editFormProps} />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostPlacePage));
