const BOOKINGS_QUERY = `
    query Bookings($id: String) {
        bookings(id: $id) {
            id
            created_at
            start_date
            end_date
            paid_price
            guests
            guests
            trip {
                id
                name
            }
        }
    }
`;

const BOOKING_QUERY = `
    query Booking($id: String) {
        booking(id: $id) {
            id
            guests
            start_date
            end_date
            payment_id
            created_at
            paid_price
            total_price
            trip_price
            customer{
                email
                fullname
                phone_number
                country      
            }
            trip {
                id
                name
                trip_images{
                image_url
                }
            }
        }
    }
`;

export { BOOKINGS_QUERY, BOOKING_QUERY };
