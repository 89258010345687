import React, { useState } from "react";
import { imagePositionUpdate, imageDelete } from "services/tripService";
import ImageInputAction from "./ImageInputAction";
import SlideNotifier from "components/site/helpers/SlideNotifier";
import { Link } from "react-router-dom";
import Modal from "components/site/helpers/Modal";
import PublishViewPage from "../publish/PublishViewPage";
import { Helmet } from "react-helmet";

export default ({ history, tripImages, setTripImages, tripId, trip }) => {
  const [message, setMessage] = useState(null);
  const [openPublish, setOpenPublish] = useState(false);
  const updateImagePosition = (oldIndex, newIndex) => {
    /*
      Sorting algorithm
      if old index is greater
        i.e. oldIndex = 5 newIndex = 1
        value of position 5 is shifted to 1
        Loop from 1(newIndex) to 5(oldIndex) i.e.
          value of 1 -> 2
          value of 2 -> 3
          value of 3 -> 4
          value of 4 -> 5

      if new index is greater
        i.e. oldIndex = 2 newIndex = 6
        value of 2 is shifted to 6
        Loop from 2(oldIndex) to 6(newIndex)
          value of 3 -> 2
          value of 4 -> 3
          value of 5 -> 4
          value of 6 -> 5
      */

    let sortData = {
      [newIndex]: tripImages[oldIndex]
    };
    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        sortData[i + 1] = tripImages[i];
      }
    } else {
      for (let i = oldIndex; i < newIndex; i++) {
        sortData[i] = tripImages[i + 1];
      }
    }
    let newOrder = {
      ...tripImages,
      ...sortData
    };

    setTripImages(newOrder);
    imagePositionUpdate(sortData, tripId).then(res => {
      setMessage("Image Order Saved");
      setTimeout(() => setMessage(null), 3000);
    });
  };

  /**
   * @Desc Delete Image
   * Update all the image positions
   */
  const deleteImage = key => {
    const deleteId = tripImages[key];
    let beforeImages = {};
    let afterImages = {};

    for (var i in tripImages) {
      if (i !== key) {
        if (i > key) {
          afterImages = { ...afterImages, [i - 1]: tripImages[i] };
        } else {
          beforeImages = { ...beforeImages, [i]: tripImages[i] };
        }
      }
    }
    setTripImages({ ...beforeImages, ...afterImages });
    imageDelete(afterImages, tripId, deleteId).then(res => {
      setMessage("Image deleted");
      setTimeout(() => setMessage(null), 3000);
    });
  };

  return (
    <div
      className="container-md postContent trip-image"
      style={{ paddingBottom: "2rem" }}
    >
      <Helmet>
        <title>Trip photos | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Photos</h1>
        <p>
          These images will be at the top of your experience page, so try
          uploading high quality photos of your experience to make a great first
          impression. You can re-arrange the image position by dragging and then
          dropping in-between images.
        </p>
        <br />
        <p className="text-primary small" style={{ marginBottom: "6px" }}>
          <strong>Tips</strong>
        </p>
        <div className="d-flex i-tips">
          <div style={{ marginRight: "15px" }}>
            <div>
              <strong>What works</strong>
            </div>
            <ul className="neutralize">
              <li className="d-flex">
                <i className="fas fa-plus text-primary"></i>
                <div>
                  Showing moments of interaction between you and your guests
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-plus text-primary"></i>
                <div>
                  Highlight the setting by showing the overall context and
                  details about the location, activity, and experience
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-plus text-primary"></i>
                <div>
                  Ensuring photos are high resolution, clear, and shot with good
                  natural light
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-plus text-primary"></i>
                <div>
                  Including shots that show parts of your experience that set it
                  apart from others
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-plus text-primary"></i>
                <div>
                  Including a variation of angles (high, low, detail, wide),
                  mostly shot vertically
                </div>
              </li>
            </ul>
          </div>
          <div>
            <div>
              <strong>What doesn't work</strong>
            </div>
            <ul className="neutralize">
              <li className="d-flex">
                <i className="fas fa-times text-danger"></i>
                <div>
                  Including selfies or photos that look staged and inauthentic
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-times text-danger"></i>
                <div>
                  Photos with filters, graphic or text overlay, blurry photos,
                  or dark and grainy photos
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-times text-danger"></i>
                <div>
                  Uploading photos of drugs, nudity, alcohol, or children
                </div>
              </li>
              <li className="d-flex">
                <i className="fas fa-times text-danger"></i>
                <div>Photos that do not show the context of the activity</div>
              </li>
            </ul>
          </div>
        </div>

        <div className="content-card">
          <ImageInputAction
            deleteImage={deleteImage}
            updateImagePosition={updateImagePosition}
            setTripImages={setTripImages}
            tripImages={tripImages}
            tripId={tripId}
          />

          <SlideNotifier
            style={{
              backgroundColor: "#28a745"
            }}
            icon={
              <svg className="svg-icon" viewBox="0 0 20 20">
                <path
                  fill="none"
                  d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                ></path>
              </svg>
            }
            message={message}
          />
        </div>
      </div>

      {openPublish && (
        <Modal
          maxWidth="960px"
          backgroundColor="#FFF"
          overlayColor="#FFF"
          noShadow={true}
          showCloseButton={true}
          onClose={() => setOpenPublish(false)}
        >
          <div className="row">
            <PublishViewPage id={tripId} />
          </div>
        </Modal>
      )}

      <div className="bottom-panel d-flex">
        <Link
          to={`/trips/edit/${tripId}/itinerary`}
          className="btn btn-default"
        >
          Back
        </Link>
        {trip.published ? (
          <Link to={`/trips`} className="btn btn-dark">
            Finish
          </Link>
        ) : (
          <button onClick={() => setOpenPublish(true)} className="btn btn-dark">
            Publish <i className="fas fa-chevron-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};
