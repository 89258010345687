import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { postTripTags } from 'services/tripService';
import { Helmet } from 'react-helmet';

export default ({ id, history, previousTripTags }) => {
  const [tripTags, setTripTags] = useState(previousTripTags);
  const [term, setTerm] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  function removeTag(name) {
    setTripTags(tripTags.filter((t) => t.name !== name));
  }

  /*
   * handle tag box input
   * If user types anything besides comma i.e. ","
   * set error to null if error exist
   * update term state
   */
  function handleTagChange(e) {
    if (!e.target.value.includes(',')) {
      if (error) setError(null);
      setTerm(e.target.value);
    }
  }

  /*
   * handles user input
   * get keyCode of user input
   * When keyCode is 13 i.e. user pressed enter
     OR
    keyCode is 8 i.e. user pressed comma ","
    call handleTag()
   */
  function onKeyDown(e) {
    const { keyCode } = e;
    if (keyCode === 13 || keyCode === 188) handleTag();
  }

  /*
   * Adds new tag to tripTags array
   * If term is not empty
   * If tag doesn't already exist in array
   * add new tag
   * If tag already exist
   * Display error
   */
  function handleTag() {
    if (term.length > 0) {
      if (tripTags.length < 5) {
        // If not empty
        // If tag is already present or not
        if (
          !tripTags.some((i) => i.name.toLowerCase() === term.toLowerCase())
        ) {
          setTripTags([...tripTags, { name: term, trip_id: id }]);
          setTerm('');
        } else {
          setError(`You've already used the tag "${term}"`);
        }
      } else {
        setError(`You can add only upto five tags`);
      }
    }
  }

  function onSubmit() {
    setSubmitting(true);
    const isValueEdited =
      JSON.stringify(tripTags) !== JSON.stringify(previousTripTags);

    if (isValueEdited) {
      return postTripTags(id, tripTags).then(({ errors, data }) => {
        if (!errors) return history.push(`/trips/edit/${id}/itinerary`);

        console.error(errors);
        alert('Something went wrong');
        setSubmitting(false);
      });
    }
    return history.push(`/trips/edit/${id}/itinerary`);
  }

  return (
    <div className="container-md postContent" style={{ paddingBottom: '2rem' }}>
      <Helmet>
        <title>Trip tags | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Add tags</h1>
        <p>
          Tags are very important when searching. Tags will not appear in any
          part of the main site but the internal system will use it to show
          relevent content when user searches using any of these keywords.
        </p>
        <div className="content-card">
          <div className="d-flex flex-wrap" style={{ margin: '0 -8px' }}>
            {tripTags.map((t) => (
              <div key={t.name}>
                <div className="s-box" style={{ margin: '8px' }}>
                  <span>{t.name}</span>
                  <button
                    className="btn-chromeless"
                    onClick={() => removeTag(t.name)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div>
            <input
              type="text"
              placeholder="i.e. cultural, spiritual, night out"
              className="form-control"
              autoComplete="off"
              name="term"
              maxLength="20"
              value={term}
              onKeyDown={onKeyDown}
              onChange={handleTagChange}
            />
          </div>
          {error && (
            <div className="validation-message text-danger">{error}</div>
          )}
        </div>

        <div className="bottom-panel d-flex">
          <Link to={`/trips/edit/${id}/types`} className="btn btn-default">
            Back
          </Link>
          {submitting ? (
            <div
              className="spi"
              style={{ margin: '12px 0', marginRight: '0' }}
            ></div>
          ) : (
            <button className="btn btn-dark" onClick={onSubmit}>
              Save <i className="fas fa-chevron-right"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
