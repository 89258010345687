import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostActions from './PostActions';
import { getTripById } from 'services/tripService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for posting place i.e. add/edit trips
 @Parms
 isEdit - True if component is being called for editing existing place
 match - passed by react-router-dom
 */
const PostTripPage = ({ isEdit, match }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [editFormProps, setEditFormProps] = useState(null);

  useEffect(() => {
    if (isEdit) {
      getTripById(match.params.id)
        .then(({ errors, data: { data } }) => {
          !errors ? setEditFormProps(data.getTripById) : console.error(errors);
          setRenderApp(true);
        })
        .catch(() => setRenderApp(true));
    } else {
      setRenderApp(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    isEdit && !editFormProps ? (
      <OnPageNotFound />
    ) : (
      <PostActions editFormProps={editFormProps} />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripPage));
