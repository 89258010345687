import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostTripTypeAction from './PostTripTypeAction';
import { getTripTypes, getAllTripTypes } from 'services/tripService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for adding/deleting trip types/theme i.e. adventures, hike, tours etc
 */
const PostTripTypePage = ({ match, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [tripTypes, setTripTypes] = useState([]);
  const [allTripTypes, setAllTripTypes] = useState([]);

  useEffect(() => {
    const promise1 = getTripTypes(match.params.id).then(
      ({ errors, data: { data } }) => {
        !errors ? setTripTypes(data.getTripTypes) : console.error(errors);
      }
    );

    const promise2 = getAllTripTypes().then(({ errors, data: { data } }) => {
      !errors ? setAllTripTypes(data.getAllTripTypes) : console.error(errors);
    });

    Promise.all([promise1, promise2])
      .then(() => setRenderApp(true))
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    !tripTypes ? (
      <OnPageNotFound />
    ) : (
      <PostTripTypeAction
        history={history}
        previousTripTypes={tripTypes}
        allTripTypes={allTripTypes}
        id={match.params.id}
      />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripTypePage));
