const CUSTOMERS_QUERY = `
    query{
        customers{
            id
            email
            fullname
            phone_number
            country
        }
    }
`;

export { CUSTOMERS_QUERY };
