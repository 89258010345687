import axios from 'services/axios';
import _ from 'lodash';

export function getAllTrips() {
  return axios.post('/graphql', {
    query: `
      query{
        getAllTrips{
          id,
          published,
          enabled,
          name,
          featured,
          trip_images{
            image_url
          }
        }
      }
    `,
  });
}

export function getTripFullDetailById(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripById($id: String!) {
        getTripById(id: $id){
         	id,
          published,
          featured,
          enabled,
          name,
          price,
          duration,
          activity_level,
          max_altitude,
          best_season,
          description,
          includes,
          excludes
          to_know,
          to_bring,
          places{
            name
          },
          trip_tags{
            name
          },
          trip_images{
            image_url
          },
          trip_itineraries{
            day,
            title,
            description,
            image_url
          },
          trip_types: trip_types_trips{
            name: trip_type_id
          }
        }
      }
    `,
    variables: { id },
  });
}

export function getTripById(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripById($id: String!) {
        getTripById(id: $id) {
          id,
          name,
          featured,
          enabled,
          published,
          price,
          duration,
          activity_level,
          max_altitude,
          best_season,
          description,
          includes,
          excludes
          to_bring,
          to_know
        }
      }
    `,
    variables: { id },
  });
}

export function addTrip(formProps) {
  return axios.post('/graphql', {
    query: `
      mutation AddTrip($enabled: Boolean!, $featured: Boolean! ,$name: String!, $price: Int!, $duration: String!, $activity_level: String!, $max_altitude: String!, $best_season: String!, $description: String!) {
        addTrip(enabled: $enabled, featured: $featured, name: $name, price: $price, duration: $duration, activity_level: $activity_level, max_altitude: $max_altitude, best_season: $best_season, description: $description) {
          id
        }
      }
    `,
    variables: formProps,
  });
}

export function editTrip(formProps) {
  return axios.post('/graphql', {
    query: `
      mutation EditTrip($id: String!, $enabled: Boolean!, $featured: Boolean! ,$name: String!, $price: Int!, $duration: String!, $activity_level: String!, $max_altitude: String!, $best_season: String!, $description: String!,$includes: String!,$excludes: String!, $to_bring: String!, $to_know: String!) {
        editTrip(id: $id, enabled: $enabled, featured: $featured, name: $name, price: $price, duration: $duration, activity_level: $activity_level, max_altitude: $max_altitude, best_season: $best_season, description: $description, includes: $includes, excludes: $excludes, to_bring: $to_bring, to_know: $to_know) {
          id
        }
      }
    `,
    variables: formProps,
  });
}

export function getTripPlaces(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripById($id: String!) {
        getTripById(id: $id) {
          places{
            id
            name
          }
        }
      }
    `,
    variables: { id },
  });
}

export function postTripPlaces(id, tripPlaces) {
  return axios.post('/graphql', {
    query: `
      mutation PostTripPlaces($id: String!, $tripPlaces: [InputTripPlace!]!) {
        postTripPlaces(id: $id, tripPlaces: $tripPlaces)
      }
    `,
    variables: { id, tripPlaces },
  });
}

export function getTripTypes(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripTypes($id: String!) {
        getTripTypes(id: $id) {
          trip_id,
          type: trip_type_id
        }
      }
    `,
    variables: { id },
  });
}

export function getAllTripTypes() {
  return axios.post('/graphql', {
    query: `
      query{
        getAllTripTypes{
          type: id,
        }
      }
    `,
  });
}

export function postTripTypes(id, tripTypes) {
  for (var key in tripTypes) {
    var obj = tripTypes[key];
    obj.trip_type_id = obj.type;
    delete obj.type;
  }

  return axios.post('/graphql', {
    query: `
      mutation PostTripTypes($id: String!, $tripTypes: [InputTripType!]!) {
        postTripTypes(id: $id, tripTypes: $tripTypes)
      }
    `,
    variables: { id, tripTypes },
  });
}

export function getTripTags(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripTags($id: String!) {
        getTripTags(id: $id) {
          name,
          trip_id
        }
      }
    `,
    variables: { id },
  });
}

export function postTripTags(id, tripTags) {
  return axios.post('/graphql', {
    query: `
      mutation PostTripTags($id: String!, $tripTags: [InputTripTag!]!) {
        postTripTags(id: $id, tripTags: $tripTags)
      }
    `,
    variables: { id, tripTags },
  });
}

export function getTripIteneraries(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripIteneraries($id: String!) {
        getTripIteneraries(id: $id) {
          id,
          day,
          title,
          description,
          image_url,
          trip_id
        }
      }
    `,
    variables: { id },
  });
}

export function postItinerary(formData) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };
  return axios.post(`/api/trips/itinerary/add`, formData, { headers });
}

export function deleteItineraryImage(id) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };
  return axios.delete(`/api/trips/itinerary/image/remove/${id}`, { headers });
}

export function deleteItinerary(id) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };
  return axios.delete(`/api/trips/itinerary/remove/${id}`, { headers });
}

export function getTripImages(id) {
  return axios.post('/graphql', {
    query: `
      query GetTripImages($id: String!) {
        getTripImages(id: $id) {
          image_url,
          position,
          trip_id
        }
      }
    `,
    variables: { id },
  });
}

export function updateImagePosition(data, productId) {}

export function imagePositionUpdate(data, tripId) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };
  /*
   * Converting object back to array
   */
  const sortData = formatObjectToArray(data);

  return axios.put('/api/trips/image/position/' + tripId, sortData, {
    headers,
  });
}

export function imageDelete(data, id, image_url) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };
  const imageData = formatObjectToArray(data);
  return axios.put(
    `/api/trips/image/delete/${id}?image_url=${image_url}`,
    imageData,
    { headers }
  );
}

function formatObjectToArray(data) {
  return _.map(data, function (value, key) {
    return { position: key, image_url: value };
  });
}

export function uploadTripImage(formData, tripId) {
  const token = localStorage.getItem('token');
  const headers = { authorization: token };
  return axios.post('/api/trips/image/upload/' + tripId, formData, { headers });
}

export function publishTrip(id) {
  return axios.post('/graphql', {
    query: `
      mutation publishTrip($id: String!) {
        publishTrip(id: $id)
      }
    `,
    variables: { id },
  });
}
