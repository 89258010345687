import React, { useState, useReducer } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextAreaAutoSize from 'components/site/helpers/TextAreaAutoSize';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default ({ setTrip, handleSubmit, trip, submitting }) => {
  const [validator] = useState(
    new SimpleReactValidator({
      locale: 'en',
      element: (message) => (
        <div className="validation-message text-danger">{message}</div>
      ),
    })
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const updateForm = ({ target }) => {
    let { name, value } = target;

    setTrip({ ...trip, [name]: value });
  };

  function onSubmit() {
    if (validator.allValid()) return handleSubmit(validator);
    validator.showMessages();
    forceUpdate();
  }

  const { includes, excludes, to_know, to_bring } = trip;
  return (
    <div className="container-md postContent" style={{ paddingBottom: '2rem' }}>
      <Helmet>
        <title>Trip detail | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Details</h1>
        <p>
          Don't be afraid to brag and get into details. Guest love getting brief
          details beforehand, makes it easier for both parties to be fully
          prepared. Also mind the grammer, you wouldn't want to look improper.
        </p>
        <div className="content-card ">
          <div className="form-group">
            <label className="label">Includes*</label>
            <TextAreaAutoSize
              name="includes"
              value={includes}
              maxLength="1400"
              placeholder="What included?"
              onChange={updateForm}
              className="form-control"
            />
            <div style={{ marginTop: '4px' }}>{includes.length} / 1400</div>
            {validator.message('includes', includes, 'required|max:1400')}
          </div>

          <div className="form-group">
            <label className="label">Excludes*</label>
            <TextAreaAutoSize
              name="excludes"
              value={excludes}
              maxLength="1400"
              placeholder="What excluded?"
              onChange={updateForm}
              className="form-control"
            />
            <div style={{ marginTop: '4px' }}>{excludes.length} / 1400</div>
            {validator.message('excludes', excludes, 'required|max:1400')}
          </div>

          <div className="form-group">
            <label className="label">What to bring*</label>
            <TextAreaAutoSize
              name="to_bring"
              value={to_bring}
              maxLength="450"
              placeholder="Anything guest should bring?"
              onChange={updateForm}
              className="form-control"
            />
            <div style={{ marginTop: '4px' }}>{to_bring.length} / 450</div>
            {validator.message('Things to bring', to_bring, 'required|max:450')}
          </div>

          <div className="form-group">
            <label className="label">Trip highlights*</label>
            <TextAreaAutoSize
              name="to_know"
              value={to_know}
              maxLength="1450"
              placeholder="Anything guest should know about the trip?"
              onChange={updateForm}
              className="form-control"
            />
            <div style={{ marginTop: '4px' }}>{to_know.length} / 1450</div>
            {validator.message('Things to know', to_know, 'required|max:1450')}
          </div>

          <div className="bottom-panel d-flex">
            <Link to={`/trips/edit/${trip.id}`} className="btn btn-default">
              Back
            </Link>
            {submitting ? (
              <div
                className="spi"
                style={{ margin: '12px 0', marginRight: '0' }}
              ></div>
            ) : (
              <button className="btn btn-dark" onClick={onSubmit}>
                Save <i className="fas fa-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
