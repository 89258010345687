import { BOOKINGS_QUERY, BOOKING_QUERY } from 'constants/bookingQueries';
import axios from 'services/axios';

export function getBookings(id) {
  return axios.post('/graphql', {
    query: BOOKINGS_QUERY,
    variables: { id },
  });
}

export function getBookingById(id) {
  return axios.post('/graphql', {
    query: BOOKING_QUERY,
    variables: { id },
  });
}
