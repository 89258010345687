import React, { useState, useEffect } from 'react';
import DashboardWrapper from 'components/site/commons/DashboardWrapper';
import { Helmet } from 'react-helmet';
import { getBookings } from 'services/bookingService';
import BookingList from './BookingList';
import SpinLoader from 'components/site/helpers/SpinLoader';

const BookingListPage = ({ location }) => {
  const [bookings, setBookings] = useState(null);

  const id = new URLSearchParams(location.search).get('booking_id');

  useEffect(() => {
    getBookings(id).then(({ data: { data } }) => setBookings(data.bookings));
  }, [id]);

  if (!bookings) return <SpinLoader />;
  return (
    <>
      <Helmet>
        <title>Bookings | Visit Nepal Dashboard</title>
      </Helmet>
      <BookingList bookings={bookings} id={id} />
    </>
  );
};

export default DashboardWrapper(BookingListPage);

/*
return (
  <div>
    <Helmet>
      <title>Bookings | Visit Nepal Dashboard</title>
    </Helmet>
    <h1 className="page-title">Bookings</h1>
    <div style={{ margin: '1.5rem 0 2.5rem' }}>
      <h2>No bookings as of yet</h2>
      <p className="text-muted">
        What are you waiting for? add more places and trips to attract
        customers
      </p>
    </div>
    <Link to="/trips/new" className="btn btn-dark">
      Add Trip
    </Link>
  </div>
);
*/
