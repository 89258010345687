import React from 'react';

export default ({ customers }) => {
  return (
    <div>
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Customers</h1>
        </div>
      </div>

      <div className="content-card ">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Email</th>
                <th style={{ whiteSpace: 'nowrap' }}>Fullname</th>
                <th style={{ whiteSpace: 'nowrap' }}>Phone number</th>
                <th style={{ whiteSpace: 'nowrap' }}>Country</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td style={{ padding: '1rem' }}>{customer.email}</td>
                  <td style={{ padding: '1rem' }}>{customer.fullname}</td>
                  <td style={{ padding: '1rem' }}>{customer.phone_number}</td>
                  <td style={{ padding: '1rem' }}>{customer.country}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
