import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class ConfirmBox extends React.Component {
  submit = () => {
    const { caption, label, onConfirm } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" style={{ padding: "15px" }}>
            <h1>Are you sure?</h1>
            <p>{caption}</p>
            <br />
            <button
              className="btn btn-default"
              style={{ marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {label}
            </button>
          </div>
        );
      }
    });
  };

  render() {
    const { btnClass, children } = this.props;
    return (
      <button type="button" className={btnClass} onClick={this.submit}>
        {children}
      </button>
    );
  }
}

export default ConfirmBox;
