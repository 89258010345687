import React, { useState } from "react";
import { publishPlace } from "services/placeService";
import { withRouter, Link } from "react-router-dom";

const PublishPlace = ({ id, imageUrl, history, published }) => {
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit() {
    setSubmitting(true);
    await publishPlace(id).then(({ errors, data }) => {
      if (errors) console.log(errors);
    });
    setSubmitting(false);
    history.push(`/places`);
  }

  return (
    <div className="bottom-panel d-flex">
      <Link className="btn btn-default" to={`/places/edit/${id}`}>
        Back
      </Link>
      {submitting ? (
        <div
          className="spi"
          style={{ margin: "12px 0", marginRight: "0" }}
        ></div>
      ) : published ? (
        <Link className="btn btn-dark" to="/places">
          Finish
        </Link>
      ) : (
        <button
          className="btn btn-dark"
          onClick={onSubmit}
          disabled={imageUrl ? false : true}
        >
          Publish
        </button>
      )}
    </div>
  );
};

export default withRouter(PublishPlace);
