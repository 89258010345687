import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import BookingView from './BookingView';
import { getBookingById } from 'services/bookingService';
import requireAuth from 'components/requireAuth';

/** @Desc
 * Root component for viewing booking
 */
const BookingViewPage = ({ history, match }) => {
  const [booking, setBooking] = useState(undefined);

  useEffect(() => {
    getBookingById(match.params.id).then(({ errors, data: { data } }) => {
      !errors ? setBooking(data.booking) : console.error(errors);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (booking === undefined) return <SpinLoader />;

  return (
    <div style={{ backgroundColor: '#FFF', paddingTop: '2rem' }}>
      <div className="container-md">
        <button
          className="btn-chromeless"
          onClick={() => history.goBack()}
          style={{ marginBottom: '10px', display: 'block' }}
        >
          <i className="fas fa-chevron-left"></i> Back
        </button>
        <hr />
        <BookingView booking={booking} />
      </div>
    </div>
  );
};

export default requireAuth(BookingViewPage);
