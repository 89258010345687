import React, { Component } from 'react';
import PostForm from './PostForm';
import { addPlace, editPlace } from 'services/placeService';
import { withRouter } from 'react-router-dom';

class PageActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProps: this.props.editFormProps
        ? {
            ...this.props.editFormProps,
            place_id: this.props.editFormProps.place_id
              ? this.props.editFormProps.place_id
              : '',
          }
        : {
            name: '',
            caption: '',
            description: '',
            place_id: '',
            enabled: true,
            place_type_id: '', // Country, Place, Region
          },
      submitting: false,
    };
  }

  /*
   * Generic handler for updating formProps[name] state | i.e. name, description, caption etc
   * takes name and value from input
   * name must match the key from formProps state object
   */
  updateForm = ({ target }) => {
    let { name, value, type, checked } = target;
    // value = name === 'place_type_id' && value === 'Country' ? '' : value;
    value = type === 'checkbox' ? checked : value;

    this.setState(
      { formProps: { ...this.state.formProps, [name]: value } },
      () => {
        // Re-assigining place id to empty if country is selected
        if (name === 'place_type_id' && value === 'Country')
          this.setState({
            formProps: { ...this.state.formProps, place_id: '' },
          });
      }
    );
  };

  /*
   * When (next) submit button in pressed
   * Form is submitted to server
   */
  handleSubmit = () => {
    /*
     * Modifying direct state.formProps reference will result in unnatural behaviour
     * Hence, using formProps clone
     */
    const formProps = { ...this.state.formProps };
    /*
     * React form doesn't support null value field so converting empty field to null before submittion
     */
    if (!formProps.place_id) formProps.place_id = null;
    this.setState({ submitting: true });

    this.props.editFormProps
      ? this.handlePlaceEdit(formProps)
      : this.handlePlaceAdd(formProps);
  };

  handlePlaceEdit = (formProps) => {
    /*
     * Comparing literal values of two different object to check if form has been edited
     */
    const isValueEdited =
      JSON.stringify(this.props.editFormProps) !== JSON.stringify(formProps);

    if (isValueEdited) {
      return editPlace(formProps).then(({ data: { data, errors } }) => {
        if (!errors)
          return this.props.history.push(`/places/edit/${formProps.id}/image`);

        console.error(errors);
        alert(
          "Something went wrong, make sure the place name doesn't already exit."
        );
        this.setState({ submitting: false });
      });
    }
    return this.props.history.push(`/places/edit/${formProps.id}/image`);
  };

  handlePlaceAdd = (formProps) => {
    addPlace(formProps).then(({ data: { data, errors } }) => {
      // IF no errors redirect to image upload page
      if (!errors)
        return this.props.history.push(
          `/places/edit/${data.addPlace.id}/image`
        );

      console.error(errors);
      alert(
        "Something went wrong, make sure the place name doesn't already exit."
      );
      this.setState({ submitting: false });
    });
  };

  render() {
    const { countries } = this.props;
    const { formProps, submitting } = this.state;
    const { handleSubmit, updateForm } = this;
    return (
      <PostForm
        actions={{ handleSubmit, updateForm }}
        states={{
          formProps,
          submitting,
          isEdit: this.props.editFormProps ? true : false,
        }}
        countries={countries}
      />
    );
  }
}
export default withRouter(PageActions);
