import React, { useState, useEffect } from 'react';
import DashboardWrapper from 'components/site/commons/DashboardWrapper';
import { Helmet } from 'react-helmet';
import CustomerList from './CustomerList';
import { getCustomers } from 'services/customerService';

import SpinLoader from 'components/site/helpers/SpinLoader';

const CustomerListPage = () => {
  const [customers, setCustomers] = useState(null);
  useEffect(() => {
    getCustomers().then(({ data: { data } }) => setCustomers(data.customers));
  }, []);

  if (!customers) return <SpinLoader />;
  return (
    <>
      <Helmet>
        <title>Customers | Visit Nepal Dashboard</title>
      </Helmet>
      <CustomerList customers={customers} />
    </>
  );
};

export default DashboardWrapper(CustomerListPage);
