import React from "react";
import "./footer.scss";

export default () => (
  <footer className="d-flex flex-wrap">
    <div>© Lalman trek and expedition (P) Ltd. All rights reserved.</div>
    <div>
      &nbsp;Coded with <i className="fa fa-heart heart"></i> by{" "}
      <a
        href="http://manjiltamang.vortexnepal.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        Manjil Tamang{" "}
      </a>
    </div>
  </footer>
);
