import React, { useState } from 'react';
import { signIn } from 'actions/auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import Footer from 'components/site/commons/Footer';
import { Helmet } from 'react-helmet';

const Login = (props) => {
  const [error, setError] = useState(null);

  const onSubmit = (formProps) => {
    setError(null);
    const { signIn } = props;
    return signIn(formProps, (error) => {
      if (error) setError(error);
    });
  };

  const { handleSubmit, submitting, invalid } = props;

  return (
    <div className="container-md">
      <Helmet>
        <title>Login | Visit Nepal Dashboard</title>
      </Helmet>
      <div
        className="login-wrapper"
        style={{
          maxWidth: '380px',
          padding: '20px 26px',
          borderRadius: '4px',
          border: '1px #ddd solid',
          margin: '30px auto',
        }}
      >
        <div className="text-center">
          <img
            src="/assets/images/logo-dashboard.svg"
            style={{ width: '150px' }}
            className="img-responsive"
            alt="Visit Nepal logo"
          />
        </div>
        <h2 className="text-center">Welcome to admin site</h2>
        <hr />
        {error && (
          <div className="error-box text-center">
            <small className="text-danger">
              <i className="fas fa-exclamation-triangle"></i> {error}
            </small>
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingBottom: 15 + 'px' }}
        >
          <Field
            type="text"
            field="input"
            label="Username"
            name="email"
            component={renderField}
          />

          <Field
            type="password"
            field="input"
            label="Password"
            name="password"
            component={renderField}
          />
          <button
            type="submit"
            className="btn btn-dark btn-block"
            disabled={invalid || submitting}
          >
            Sign In
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

function renderField(field) {
  const { meta } = field;
  const lblError = meta.touched && meta.error ? 'text-danger' : '';

  return (
    <div className="form-group">
      <label className={lblError}>
        <small>{meta.touched && meta.error ? meta.error : field.label}</small>
      </label>
      <field.field
        className="form-control"
        type={field.type}
        autoComplete="off"
        {...field.input}
      />
    </div>
  );
}

/*
 Redux Custom validation. Gets called upon each form actions
 */
function validate(values) {
  const errors = {};
  // Validate the inputs from 'values'
  if (!values.email) {
    errors.email = 'Please enter username';
  }
  if (!values.password) {
    errors.password = 'Please enter password';
  }
  return errors;
}

export default compose(
  connect(null, { signIn }),
  reduxForm({
    validate,
    form: 'signInForm',
  }),
  withRouter
)(Login);
