import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Dropzone from "react-dropzone";
import ConfirmBox from "components/site/helpers/ConfirmBox";
import _ from "lodash";
import { getImageSource } from "services/imageService";

/*
 * Component for image dropzone and display
 */
class ImageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzone: {
        disableDropzone: false,
        dropHovered: false
      },
      disableSort: false
    };
  }

  /*
   * Gets called each time images are added to the dropzone
   */
  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      dropzone: { ...this.state.dropzone, dropHovered: false },
      errors: []
    });
    this.props.onImageDrop(
      acceptedFiles,
      rejectedFiles,
      this.enableSorting,
      this.disableSorting
    );
  };

  /**
  * @Desc Enables sorting
  * Checks if any of the images object have null value.
    Null value means the image is currently being uploaded
    Hence sorting is kept disabled until all images have finished uploading
  */
  enableSorting = () => {
    const disabled = _.some(this.props.tripImages, i => {
      return i === null;
    });
    if (!disabled) this.setState({ disableSort: false });
  };

  /*
   * Disables sorting while product images is being uploaded
   */
  disableSorting = () => {
    this.setState({ disableSort: true });
  };

  render() {
    const { tripImages, deleteImage } = this.props;
    const { dropzone, disableSort } = this.state;
    const noImageCss =
      _.size(tripImages) > 0 ? "img-dz s-grid" : "img-dz f-grid";
    const dropZoneClass = dropzone.dropHovered
      ? `dz-hov ${noImageCss}`
      : noImageCss;

    return (
      <div className="img-set">
        {_.map(tripImages, (value, key) => (
          <SortableItem
            deleteImage={deleteImage}
            disabled={disableSort}
            key={key}
            index={parseInt(key, 10)}
            data={{ key, value }}
          />
        ))}
        {Object.keys(tripImages).length < 5 && (
          <Dropzone
            accept="image/png,image/jpeg"
            onDrop={this.onDrop}
            disabled={this.state.disableDropzone}
            onDragEnter={() => {
              this.setState({
                dropzone: { ...this.state.dropzone, dropHovered: true }
              });
            }}
            onDragLeave={() => {
              this.setState({
                dropzone: { ...this.state.dropzone, dropHovered: false }
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={dropZoneClass}>
                <input {...getInputProps()} />
                <div className="dz-caption">
                  {dropzone.dropHovered ? (
                    <div>
                      <p className="text-center">
                        <i className="far fa-images"></i> Drop image to upload
                      </p>
                    </div>
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      role="img"
                      aria-label="Add photo"
                      focusable="false"
                    >
                      <path
                        d="m18.5 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0-4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm4-4h-21c-.83 0-1.5.67-1.5 1.5v17.01c0 .82.68 1.49 1.5 1.49h20.99c.84 0 1.5-.67 1.5-1.5v-17c .01-.83-.66-1.5-1.49-1.5zm-21.5 1.5c0-.28.22-.5.5-.5h20.99c.28 0 .5.23.5.5v13.8l-5.15-5.15a.5.5 0 0 0 -.65-.05l-3.65 2.74-4.69-4.69a.5.5 0 0 0 -.71 0l-7.14 7.14zm22 17.01a.49.49 0 0 1 -.5.5h-21a .5.5 0 0 1 -.5-.5v-1.8l7.5-7.5 4.65 4.65a.5.5 0 0 0 .65.05l3.65-2.74 5.55 5.54z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}
export default SortableContainer(ImageList);

/*
 * Returns list of product images
 * null value means the product is currently being uploaded to server
 */
const SortableItem = SortableElement(({ data, deleteImage }) => {
  if (data.value) {
    return (
      <div className="i-wrap">
        <div className="_out">
          <div className="_in">
            <ConfirmBox
              label="Yes, Delete it!"
              caption="This action can’t be undone"
              btnClass="btn btn-sm btn-default remove-btn"
              onConfirm={() => deleteImage(data.key)}
            >
              <i className="fas fa-trash"></i>
            </ConfirmBox>
            <img
              src={getImageSource(data.value, 560, 560)}
              className="img-fluid"
              alt={"trip"}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="i-wrap">
        <div className="_out">
          <div className="_in">
            <div style={{ position: "absolute" }}>
              <div className="spi"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
