import React, { useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { postTripPlaces } from 'services/tripService';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';

export default ({ id, history, previousTripPlaces, allPlaces }) => {
  const [tripPlaces, setTripPlaces] = useState(previousTripPlaces);
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [term, setTerm] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      locale: 'en',
      element: (message) => (
        <div className="validation-message text-danger">{message}</div>
      ),
    })
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function onSubmit() {
    if (validator.allValid()) return handleSubmit();
    validator.showMessages();
    forceUpdate();
  }

  function handleSubmit() {
    setSubmitting(true);
    const isValueEdited =
      JSON.stringify(tripPlaces) !== JSON.stringify(previousTripPlaces);

    /*
     *** No longer necessary for deep cloning, code refactored ***
     * https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
     */
    // const deepClonedTripPlaces = JSON.parse(JSON.stringify(tripPlaces));
    if (isValueEdited) {
      const trip_places = tripPlaces.map((tp) => ({
        place_id: tp.id,
        trip_id: id,
      }));

      return postTripPlaces(id, trip_places).then(({ errors, data }) => {
        if (!errors) return history.push(`/trips/edit/${id}/types`);

        console.error(errors);
        alert('Something went wrong');
        setSubmitting(false);
      });
    }
    return history.push(`/trips/edit/${id}/types`);
  }

  function onPlaceSelection(place) {
    if (tripPlaces.some((t) => t.id === place.id)) {
      removeTheme(place.id);
    } else {
      // add new place
      setTripPlaces([...tripPlaces, { id: place.id, name: place.name }]);
    }
  }

  function removeTheme(placeId) {
    setTripPlaces(tripPlaces.filter((t) => t.id !== placeId));
  }

  function handleInputBlur() {
    setTimeout(() => {
      setShowSuggestionList(false);
    }, 500);
  }

  return (
    <div className="container-md postContent" style={{ paddingBottom: '2rem' }}>
      <Helmet>
        <title>Trip places | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Add places</h1>
        <p>
          Add all places, country and region where you can get this experience.
          For example Chitwan jungle safari will have places like Nepal, Chitwan
          and Middle Nepal. Don't leave out any places.
        </p>
        <div className="content-card">
          <div className="d-flex flex-wrap" style={{ margin: '0 -8px' }}>
            {tripPlaces.map((place) => (
              <div key={place.name}>
                <div className="s-box" style={{ margin: '8px' }}>
                  <span>{place.name}</span>
                  <button
                    className="btn-chromeless"
                    onClick={() => removeTheme(place.id)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div style={{ position: 'relative' }}>
            <input
              type="text"
              placeholder="Search for places"
              className="form-control search-input"
              autoComplete="off"
              name="term"
              onFocus={() => setShowSuggestionList(true)}
              onBlur={handleInputBlur}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
            {validator.message('Place', tripPlaces, 'required|array|min:1')}
            {showSuggestionList && (
              <div className="suggestion-list">
                {allPlaces
                  .filter((f) =>
                    f.name.toLowerCase().includes(term.toLowerCase())
                  )
                  .map((t) => (
                    <div key={t.id} className="s-item-w">
                      <label className="s-item">
                        <input
                          className="custom-input-checkbox"
                          type="checkbox"
                          name="collection-oncheck"
                          onChange={() => onPlaceSelection(t)}
                          checked={tripPlaces.some((tt) => tt.name === t.name)}
                          value={t.id}
                        />
                        <div className="custom-checkbox">
                          <div className="checkbox" />
                          <div className="checkbox-label">{t.name}</div>
                        </div>
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>

        <div className="bottom-panel d-flex">
          <Link to={`/trips/edit/${id}/details`} className="btn btn-default">
            Back
          </Link>
          {submitting ? (
            <div
              className="spi"
              style={{ margin: '12px 0', marginRight: '0' }}
            ></div>
          ) : (
            <button className="btn btn-dark" onClick={onSubmit}>
              Save <i className="fas fa-chevron-right"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
