import { CDN_URL } from "config";

export function getImageSource(image_url, width, height) {
  if (image_url) {
    if (process.env.REACT_APP_ENV === "DEV") {
      return image_url;
    } else {
      /* Extracting just name from image url */
      const imageName = image_url.match(/[\w-]+\.jpg/g)[0];
      return `${CDN_URL}/${width}x${height}/${imageName}`;
    }
  } else {
    return "/assets/images/no-image.jpg";
  }
}
