import React, { useEffect } from 'react';
import { signOut } from 'actions/auth';
import { connect } from 'react-redux';
import './topNav.scss';

const TopNav = ({ auth, signOut }) => {
  let appNode;

  useEffect(() => {
    const dropdownButtons = document.querySelectorAll('.dropdown-toggle');
    dropdownButtons.forEach((btn) => {
      // When button is clicked to toggle dropdown
      btn.addEventListener('click', function () {
        const dropdown = btn.nextSibling;
        toggleDropdown(dropdown);
      });
    });
  });

  function toggleDropdown(dropdown) {
    if (dropdown) {
      const { classList } = dropdown;
      classList.contains('show')
        ? classList.remove('show')
        : classList.add('show');
    }
  }

  function onBlur(e) {
    const dropdown = e.currentTarget.nextSibling;
    setTimeout(() => {
      toggleDropdown(dropdown);
    }, 100);
  }

  function onMenuIconClick() {
    if (!appNode) appNode = document.getElementById('mjlApp');

    if (appNode) {
      const { classList } = appNode;
      classList.contains('nav-open')
        ? classList.remove('nav-open')
        : classList.add('nav-open');
    }
  }

  function onLogout() {
    signOut();
  }

  return (
    <nav className="nav-top d-flex">
      <button onClick={onMenuIconClick} className="btn-chromeless hamburger">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          role="img"
          aria-label="Open navigation menu"
        >
          <path d="M0 9.333v-2.667h32v2.667h-32zM10.667 25.333v-2.667h21.333v2.667h-21.333zM5.333 17.333v-2.667h26.667v2.667h-26.667z"></path>
        </svg>
      </button>

      <div></div>

      <ul className="nav-links neutralize list-inline">
        <li className="dropdown">
          <button
            tabIndex="-1"
            onBlur={onBlur}
            className="btn-chromeless d-flex dropdown-toggle"
            style={{ alignItems: 'center' }}
          >
            <div className="nav-avatar">{auth.email.charAt(0)}</div>
            <strong>{auth.email}</strong>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a
                href="https://himalayanhike.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Main site{' '}
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <button onClick={onLogout} className="btn-chromeless">
                Log out
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { signOut })(TopNav);
