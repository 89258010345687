import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import 'components/site/commons/dashboardWrapper.scss';
import TopNav from 'components/site/commons/TopNav';

export default (ChildComponent) => (props) => (
  <div id="mjlApp">
    <nav className="nav-right">
      <div
        className="nav-brand d-flex"
        style={{ justifyContent: 'space-between' }}
      >
        <div className="img-wrap">
          <img src="/assets/images/logo.svg" alt="logo" />
        </div>
        <Link
          to="/trips"
          className="btn btn-default btn-sm"
          style={{ padding: '10px 35px', backgroundColor: 'transparent' }}
        >
          Exit
        </Link>
      </div>
      <ul className="nav-links neutralize">
        <RenderLink isEdit={props.isEdit} id={props.match.params.id} />
      </ul>
    </nav>
    <div className="_main_panel">
      <TopNav />
      <div className="_bod_wrap">
        <div className="_bod_content">
          <ChildComponent {...props} />
        </div>
      </div>
    </div>
  </div>
);

function RenderLink({ isEdit, id }) {
  if (isEdit) {
    return (
      <>
        <li>
          <NavLink to={`/trips/edit/${id}`} exact={true}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Basic Information
          </NavLink>
        </li>
        <li>
          <NavLink to={`/trips/edit/${id}/details`}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Details
          </NavLink>
        </li>
        <li>
          <NavLink to={`/trips/edit/${id}/places`}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Places
          </NavLink>
        </li>
        <li>
          <NavLink to={`/trips/edit/${id}/types`}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Theme
          </NavLink>
        </li>

        <li>
          <NavLink to={`/trips/edit/${id}/tags`}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Tags
          </NavLink>
        </li>

        <li>
          <NavLink to={`/trips/edit/${id}/itinerary`}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Itinerary
          </NavLink>
        </li>

        <li>
          <NavLink to={`/trips/edit/${id}/photos`}>
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Photos
          </NavLink>
        </li>
      </>
    );
  } else {
    return (
      <>
        <li>
          <NavLink to="/trips/new">
            <div className="s-icon">
              <i className="fas fa-circle"></i>
            </div>
            Basic Information
          </NavLink>
        </li>
        <li>
          <button>
            <div className="s-icon">
              <i className="fas fa-lock text-muted"></i>
            </div>
            Details
          </button>
        </li>
        <li>
          <button>
            <div className="s-icon">
              <i className="fas fa-lock text-muted"></i>
            </div>
            Places
          </button>
        </li>
        <li>
          <button>
            <div className="s-icon">
              <i className="fas fa-lock text-muted"></i>
            </div>
            Theme
          </button>
        </li>

        <li>
          <button>
            <div className="s-icon">
              <i className="fas fa-lock text-muted"></i>
            </div>
            Tags
          </button>
        </li>

        <li>
          <button>
            <div className="s-icon">
              <i className="fas fa-lock text-muted"></i>
            </div>
            Itinerary
          </button>
        </li>

        <li>
          <button>
            <div className="s-icon">
              <i className="fas fa-lock text-muted"></i>
            </div>
            Photos
          </button>
        </li>
      </>
    );
  }
}
