import React, { useState, useEffect } from 'react';
import SlideNotifier from 'components/site/helpers/SlideNotifier';
import Dropzone from 'react-dropzone';
import { uploadPlaceImage, deletePlaceImage } from 'services/placeService';
import ConfirmBox from 'components/site/helpers/ConfirmBox';
import PublishPlace from '../PublishPlace';
import { getImageSource } from 'services/imageService';
import { Helmet } from 'react-helmet';

export default ({ placeId, place, setPlace }) => {
  const [message, setMessage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dropzone, setDropzone] = useState({
    disable: false,
    hovering: false,
  });

  useEffect(() => {
    setDropzone({ ...dropzone, disable: place.image_url ? true : false });
  }, [place.image_url]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setMessage('Only single picture allowed');
      setTimeout(() => setMessage(null), 3000);
    } else if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size > 1000000) {
        // If image is greater then 1MB
        setMessage('Image size is too big');
        setTimeout(() => setMessage(null), 3000);
      } else {
        const imageFile = acceptedFiles[0];
        handleImageUpload(imageFile);
      }
    } else {
      setMessage('Something went wrong');
      setTimeout(() => setMessage(null), 3000);
    }
  };

  const handleImageUpload = (image) => {
    let formData = new FormData();
    formData.append('image', image);
    setUploading(true);
    uploadPlaceImage(placeId, formData)
      .then(({ data: { image_url } }) => {
        setPlace({ ...place, image_url });
        setUploading(false);
      })
      .catch(() => setUploading(false));
  };

  const handleImageDelete = () => {
    setPlace({ ...place, image_url: null });
    deletePlaceImage(placeId).catch((err) => console.log(err));
  };

  const dropZoneClass = dropzone.hovering
    ? `place-dropzone active`
    : 'place-dropzone';

  return (
    <div className="container-md postContent" style={{ paddingBottom: '2rem' }}>
      <Helmet>
        <title>Place photo | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Cover photo for {place.name}</h1>
        <p>
          This image will be at the very top of {place.name} page, so try
          uploading high quality photo to make a great first impression. You can
          always come back and change this later.
        </p>

        <div className="content-card">
          <Dropzone
            disabled={dropzone.disable}
            accept="image/png,image/jpeg"
            onDrop={handleOnDrop}
            multiple={false}
            onDragEnter={() => {
              setDropzone({ ...dropzone, dropHovered: true });
            }}
            onDragLeave={() => {
              setDropzone({ ...dropzone, dropHovered: false });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={dropZoneClass}>
                <input {...getInputProps()} />
                {place.image_url ? (
                  <RenderImage
                    place={place}
                    onImageDelete={handleImageDelete}
                  />
                ) : (
                  <div className="caption">
                    {uploading ? (
                      <div>
                        <div className="spi" style={{ marginBottom: '10px' }} />
                        Uploading...
                      </div>
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        role="img"
                        aria-label="Add photo"
                        focusable="false"
                      >
                        <path
                          d="m18.5 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0-4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm4-4h-21c-.83 0-1.5.67-1.5 1.5v17.01c0 .82.68 1.49 1.5 1.49h20.99c.84 0 1.5-.67 1.5-1.5v-17c .01-.83-.66-1.5-1.49-1.5zm-21.5 1.5c0-.28.22-.5.5-.5h20.99c.28 0 .5.23.5.5v13.8l-5.15-5.15a.5.5 0 0 0 -.65-.05l-3.65 2.74-4.69-4.69a.5.5 0 0 0 -.71 0l-7.14 7.14zm22 17.01a.49.49 0 0 1 -.5.5h-21a .5.5 0 0 1 -.5-.5v-1.8l7.5-7.5 4.65 4.65a.5.5 0 0 0 .65.05l3.65-2.74 5.55 5.54z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                )}
              </div>
            )}
          </Dropzone>

          <p
            style={{
              borderLeft: '4px solid rgb(0, 132, 137)',
              paddingLeft: '8px',
              marginBottom: '1rem',
            }}
          >
            Choose a photo that represents the place. Tips - Optimum dimension
            for image is around <strong>1440 x 800</strong>.
          </p>
        </div>

        <SlideNotifier
          style={{
            backgroundColor: '#a94442',
          }}
          icon={
            <svg className="svg-icon" viewBox="0 0 20 20">
              <path
                fill="none"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
              ></path>
            </svg>
          }
          message={message}
        />
        <PublishPlace
          id={placeId}
          published={place.published}
          imageUrl={place.image_url}
        />
      </div>
    </div>
  );
};

const RenderImage = ({
  place: { name, caption, image_url },
  onImageDelete,
}) => (
  <div className="image-panel">
    <ConfirmBox
      label="Yes, Delete it!"
      caption="Deleting cover image will unpublish place as well, continue?"
      btnClass="btn-chromeless"
      onConfirm={onImageDelete}
    >
      <svg className="svg-icon" viewBox="0 0 20 20">
        <path
          fill="none"
          d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
        ></path>
      </svg>
    </ConfirmBox>
    <img src={getImageSource(image_url, 1200, 630)} alt={name} />
    <div className="image-caption">
      <h1>{name}</h1>
      <p>{caption}</p>
    </div>
  </div>
);
