import React, { useState, useEffect } from 'react';
import { getPlaceById } from 'services/placeService';
import SpinLoader from 'components/site/helpers/SpinLoader';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import PostImageForm from './PostImageForm';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

const PostPlaceImagePage = ({ match }) => {
  const [place, setPlace] = useState(null);
  const [renderApp, setRenderApp] = useState(null);

  const { id } = match.params; // fetching place id from the url

  useEffect(() => {
    getPlaceById(id).then(({ errors, data: { data } }) => {
      !errors ? setPlace(data.getPlaceById) : console.error(errors);

      setRenderApp(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    place ? (
      <PostImageForm placeId={id} place={place} setPlace={setPlace} />
    ) : (
      <OnPageNotFound />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostPlaceImagePage));
