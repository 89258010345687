import React from 'react';
import { getImageSource } from 'services/imageService';

const PublishView = ({
  trip: {
    name,
    duration,
    activity_level,
    description,
    max_altitude,
    best_season,
    includes,
    excludes,
    to_know,
    to_bring,
    featured,
    enabled,
    price,
    trip_itineraries,
    trip_types,
    trip_images,
    trip_tags,
    places,
  },
}) => {
  return (
    <div className="tripview-page" style={{ paddingBottom: '2rem' }}>
      <h1 className="page-title">{name}</h1>

      <div className="t-detail">
        <div>Duration: {duration}</div>
        <div>Activity level: {activity_level}</div>
        <div>Max altitude: {max_altitude}</div>
        <div>Best season: {best_season}</div>
        <div>
          Price: <strong>USD {price}/-</strong>
        </div>
        <div>
          Enabled:{' '}
          <span
            className={`badge ${enabled ? 'badge-success' : 'badge-danger'}`}
          >
            {enabled ? 'Enabled' : 'Not enabled'}
          </span>
        </div>
        <div>
          Featured:{' '}
          <span
            className={`badge ${featured ? 'badge-success' : 'badge-danger'}`}
          >
            {enabled ? 'Featured' : 'Not featured'}
          </span>
        </div>
      </div>
      <div className="t-info">
        <div>
          <h3>What we'll do</h3>
          <div className="rd-desc">{_renderDescription(description)}</div>
        </div>

        <div>
          <h3>What's included</h3>
          <div className="rd-desc">{_renderDescription(includes)}</div>
        </div>

        <div>
          <h3>What's excluded</h3>
          <div className="rd-desc">{_renderDescription(excludes)}</div>
        </div>

        <div>
          <h3>What to bring</h3>
          <div className="rd-desc">{_renderDescription(to_bring)}</div>
        </div>
        <div>
          <h3>Things to know</h3>
          <div className="rd-desc">{_renderDescription(to_know)}</div>
        </div>
      </div>

      <div className="_wrap">
        <h1 className="page-title">Places</h1>
        <div className="d-flex flex-wrap">
          {places.length > 0 ? (
            places.map((place) => (
              <div key={place.name} className="_box">
                {place.name}
              </div>
            ))
          ) : (
            <div className="error-box text-danger">
              <i className="fas fa-exclamation-triangle"></i> No places
            </div>
          )}
        </div>
      </div>

      <div className="_wrap">
        <h1 className="page-title">Themes</h1>
        <div className="d-flex flex-wrap">
          {trip_types.length > 0 ? (
            trip_types.map((t) => (
              <div key={t.name} className="_box">
                {t.name}
              </div>
            ))
          ) : (
            <div className="error-box text-danger">
              <i className="fas fa-exclamation-triangle"></i> No themes
            </div>
          )}
        </div>
      </div>

      <div className="_wrap">
        <h1 className="page-title">Search tags</h1>
        <div className="d-flex flex-wrap">
          {trip_tags.length > 0 ? (
            trip_tags.map((t) => (
              <div key={t.name} className="_box">
                {t.name}
              </div>
            ))
          ) : (
            <div>-</div>
          )}
        </div>
      </div>

      <div className="_wrap">
        <h1 className="page-title">Photos</h1>
        <div className="d-flex flex-wrap">
          {trip_images.length > 0 ? (
            trip_images.map((t) => (
              <img
                key={t.image_url}
                src={getImageSource(t.image_url, 270, 350)}
                alt="trip"
                className="img-cover"
              />
            ))
          ) : (
            <div className="error-box text-danger">
              <i className="fas fa-exclamation-triangle"></i> No photos
            </div>
          )}
        </div>
      </div>

      {trip_itineraries.length > 0 && (
        <main className="itinerary d-flex flex-wrap">
          <div className="item-left">
            <h1 className="page-title">Your itinerary</h1>
          </div>
          <div className="item-right">
            {trip_itineraries.map((i, index) => (
              <div className="item d-flex" key={i.day}>
                <div className="v-rule">
                  {index !== trip_itineraries.length - 1 && (
                    <div className="v-line"></div>
                  )}
                </div>

                <div style={{ paddingBottom: '36px', flexGrow: 1 }}>
                  <div
                    style={{
                      marginBottom: '12px',
                      marginTop: '-10px',
                      color: '#484848',
                    }}
                  >
                    Day {i.day}
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className="item-i-left">
                      <div className="img-wrap">
                        <div className="img-i-wrap">
                          <img
                            className="img-cover rounded"
                            alt={i.title}
                            src={getImageSource(i.image_url, 560, 560)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item-i-right">
                      <div className="i-heading">{i.title}</div>
                      <div className="i-desc">{i.description}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      )}
    </div>
  );
};

function _renderDescription(desc) {
  const count = desc.length;

  function onMoreClick(e) {
    const node = e.target.previousSibling;
    node.innerHTML = desc;
    e.target.remove();
  }

  if (count > 300) {
    return (
      <div>
        <span>{`${desc.substring(0, 300)}...`}</span>
        <button onClick={onMoreClick} className="btn-chromeless">
          read more
        </button>
      </div>
    );
  }
  return desc;
}

export default PublishView;
