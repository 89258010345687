import React, { useState, useEffect } from 'react';
import DashboardWrapper from 'components/site/commons/DashboardWrapper';
import { Link } from 'react-router-dom';
import requireAuth from 'components/requireAuth';
import SpinLoader from 'components/site/helpers/SpinLoader';
import { getAllPlaces } from 'services/placeService';
import { getImageSource } from 'services/imageService';
import { Helmet } from 'react-helmet';

const PlaceListPage = () => {
  const [places, setPlaces] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAllPlaces().then(({ errors, data: { data } }) => {
      !errors ? setPlaces(data.getAllPlaces) : console.error(errors);
    });
  }, []);

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <Helmet>
        <title>Places | Visit Nepal Dashboard</title>
      </Helmet>
      {places ? (
        places.length > 0 ? (
          <RenderPlaces
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            places={places}
          />
        ) : (
          <div>
            <h1 className="page-title">Places</h1>
            <div style={{ margin: '1.5rem 0 2.5rem' }}>
              <h2>What are you waiting for?</h2>
              <p className="text-muted">
                Add countries, cities and region. Let people know your vast
                travel catalogues.
              </p>
            </div>
            <Link to="/places/new" className="btn btn-dark">
              Add Place
            </Link>
          </div>
        )
      ) : (
        <SpinLoader />
      )}
    </>
  );
};
export default requireAuth(DashboardWrapper(PlaceListPage));

function RenderPlaces({ places, searchTerm, handleSearch }) {
  return (
    <div>
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Places</h1>
        </div>
        <div className="head-right">
          <Link to="/places/new" className="btn btn-dark btn-sm">
            Add new place
          </Link>
        </div>
      </div>

      <div className="content-card ">
        <div
          style={{
            marginBottom: '15px',
          }}
        >
          <input
            value={searchTerm}
            onChange={handleSearch}
            className="form-control search-input"
            placeholder="Filter using place name"
          />
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th />
                <th>Status</th>
                <th>Enabled</th>
                <th>Type</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {places
                .filter((place) =>
                  place.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((p) => (
                  <tr key={p.id}>
                    <td style={{ width: '70px', height: '70px' }}>
                      <Link to={`/places/edit/${p.id}/image`}>
                        <img
                          className="rounded"
                          style={{
                            objectFit: 'cover',
                            width: '70px',
                            height: '50px',
                          }}
                          src={
                            p.image_url
                              ? getImageSource(p.image_url, 190, 190)
                              : getImageSource(null)
                          }
                          alt={p.name}
                        />
                      </Link>
                    </td>
                    <td>
                      <Link className="link" to={`/places/edit/${p.id}`}>
                        {p.name}
                      </Link>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          p.published ? 'badge-success' : 'badge-warning'
                        }`}
                      >
                        {p.published ? 'Published' : 'In progress'}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          p.enabled ? 'badge-success' : 'badge-danger'
                        }`}
                      >
                        {p.enabled ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td>{p.place_type_id}</td>
                    <td>
                      <Link
                        className="btn btn-sm btn-default"
                        to={`/places/edit/${p.id}`}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
