import React from 'react';
import { formatDate } from 'services/utils';
import { Link } from 'react-router-dom';
import './bookingView.scss';

export default ({
  booking: {
    id,
    guests,
    start_date,
    end_date,
    payment_id,
    created_at,
    paid_price,
    total_price,
    trip_price,
    trip,
    customer: { fullname, email, phone_number, country },
  },
}) => {
  const tripName = trip.name;
  const tripId = trip.id;
  return (
    <div id="bookingViewPage">
      <h2 className="page-title">Booking information</h2>
      <ul className="neutralize" style={{ borderBottom: 0, marginBottom: 0 }}>
        <li>Booking code: {id.toUpperCase()}</li>
        <li>
          Payment id:{' '}
          <a
            className="link"
            href={`https://dashboard.stripe.com/test/search?query=${payment_id}`}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {' '}
            {payment_id}
          </a>
        </li>
      </ul>
      <h3>Trip information</h3>
      <ul className="neutralize">
        <li>
          Trip name:{' '}
          <Link className="link" to={`/trips/view/${tripId}`}>
            {tripName}
          </Link>
        </li>
        <li>
          No. of guests: <strong>{guests}</strong>
        </li>
        <li>Booking date: {formatDate(new Date(created_at))}</li>
        <li>Start date: {formatDate(new Date(start_date))}</li>
        <li>End date: {formatDate(new Date(end_date))}</li>
      </ul>
      <h3>Guest information</h3>
      <ul className="neutralize">
        <li>
          Guest name: <strong>{fullname}</strong>
        </li>
        <li>Email: {email}</li>
        <li>Phone number: {phone_number}</li>
        <li>Country: {country}</li>
      </ul>
      <h3>Payment information</h3>
      <ul className="neutralize">
        <li>Trip price: USD ${trip_price}</li>
        <li>Total price: USD ${total_price}</li>
        <li>
          Paid price: <strong>USD ${paid_price}</strong>
        </li>
      </ul>
    </div>
  );
};
