import React from "react";
import "./modal.css";

const Modal = ({
  maxWidth,
  height,
  backgroundColor,
  overlayColor,
  noShadow,
  showCloseButton,
  onClose,
  children
}) => {
  const modalStyles = {
    backgroundColor: backgroundColor
  };

  if (maxWidth) modalStyles.maxWidth = maxWidth;
  if (height) modalStyles.height = height;
  if (noShadow) modalStyles.boxShadow = "none";

  return (
    <div className="mjl modal" style={{ backgroundColor: overlayColor }}>
      <div className="modal-dialog" style={modalStyles}>
        {showCloseButton && (
          <div className="modal-header">
            <button className="btn-chromeless closeModal" onClick={onClose}>
              <svg width="29" height="29">
                <path
                  d="M20.13 8.11l-5.61 5.61-5.609-5.61-.801.801 5.61 5.61-5.61 5.61.801.8 5.61-5.609 5.61 5.61.8-.801-5.609-5.61 5.61-5.61"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        )}
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};
export default Modal;
