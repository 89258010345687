import React, { useState, useReducer } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextAreaAutoSize from 'components/site/helpers/TextAreaAutoSize';
import { Helmet } from 'react-helmet';

export default ({
  actions: { handleSubmit, updateForm },
  states: { formProps, submitting, isEdit },
  countries,
}) => {
  const [validator] = useState(
    new SimpleReactValidator({
      locale: 'en',
      element: (message) => (
        <div className="validation-message text-danger">{message}</div>
      ),
    })
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function onSubmit() {
    if (validator.allValid()) return handleSubmit(validator);
    validator.showMessages();
    forceUpdate();
  }

  const {
    name,
    enabled,
    caption,
    description,
    place_id,
    place_type_id,
  } = formProps;

  validator.purgeFields();
  return (
    <div className="container-md postContent">
      <Helmet>
        <title>
          {isEdit ? 'Update place' : 'Add place'} | Visit Nepal Dashboard
        </title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">{isEdit ? 'Update' : 'Add'} place</h1>
        <p>
          Create countries, cities, region for your site. Also make sure there
          would be enough trips in this place to later add in this place so that
          customers would have a lot of experiences to choose from.
        </p>
        <hr />

        <div className="content-card ">
          <div className="form-group">
            <label className="label">
              <strong>Enable</strong>
            </label>
            <label className="switch">
              <input
                type="checkbox"
                name="enabled"
                checked={enabled}
                onChange={updateForm}
              />
              <span className="slider round"></span>
            </label>
            <div>
              <small
                style={{ marginTop: '8px' }}
                className="form-text text-muted"
              >
                By enabling, this place will be visible in main site.
              </small>
            </div>
          </div>

          <div className="form-group">
            <RenderField
              field="input"
              label="Place name*"
              name="name"
              maxLength="50"
              value={name}
              placeholder="Country or city or region"
              onChange={updateForm}
            />
            {validator.message('name', name, 'required|max:50|alpha_num_space')}
          </div>

          <div className="form-group">
            <RenderField
              field="input"
              label="Caption"
              name="caption"
              maxLength="90"
              value={caption}
              placeholder="A short captivating caption"
              onChange={updateForm}
            />
            {validator.message('caption', caption, 'max:90')}
          </div>

          <div className="form-group">
            <label className="label">Description*</label>
            <TextAreaAutoSize
              name="description"
              value={description}
              maxLength="750"
              placeholder="Write a delightful description about the place"
              onChange={updateForm}
              className="form-control"
            />
            <div style={{ marginTop: '4px' }}>{description.length} / 750</div>
            {validator.message('description', description, 'required|max:750')}
          </div>

          <div className="form-group">
            <RenderPlaceTypeCheckbox
              currentValue={place_type_id}
              types={['Country', 'Place', 'Region']}
              onChange={updateForm}
            />
            {validator.message('Place type', place_type_id, 'required')}
          </div>

          {(place_type_id === 'Place' || place_type_id === 'Region') && (
            <div className="form-group">
              <RenderSelectField
                name="place_id"
                value={place_id}
                onChange={updateForm}
                label="Select country*"
                options={countries}
              />
              {validator.message('Country', place_id, 'required')}
            </div>
          )}

          <div className="bottom-panel d-flex">
            <div />
            {submitting ? (
              <div
                className="spi"
                style={{ margin: '12px 0', marginRight: '0' }}
              ></div>
            ) : (
              <button className="btn btn-dark" onClick={onSubmit}>
                {isEdit ? (
                  <>
                    Save <i className="fas fa-chevron-right"></i>
                  </>
                ) : (
                  'Next'
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
/*
 helper component for rendering input field
*/
function RenderField(props) {
  return (
    <div>
      <label className="label">{props.label}</label>
      <props.field autoComplete="off" className="form-control" {...props} />
    </div>
  );
}

function RenderSelectField({ value, label, name, onChange, options }) {
  return (
    <div>
      <label className="label" htmlFor={`id${name}`}>
        {label}
      </label>
      <select
        className="form-control"
        name={name}
        value={value}
        onChange={onChange}
        id={`id${name}`}
      >
        <option value="">Choose country</option>
        {options.map((i) => (
          <option key={i.id} value={i.id}>
            {i.name}
          </option>
        ))}
      </select>
    </div>
  );
}

function RenderPlaceTypeCheckbox({ types, onChange, currentValue }) {
  return (
    <div>
      <label className="label">What type of place are you posting?*</label>
      <div>
        {types.map((type) => {
          return (
            <div key={type} style={{ marginBottom: '8px' }}>
              <input
                style={{ display: 'none' }}
                value={type}
                type="radio"
                name="place_type_id"
                id={`checkbox${type}`}
                className="custom-input-radio"
                checked={currentValue === type}
                onChange={onChange}
              />
              <label className="custom-radio" htmlFor={`checkbox${type}`}>
                <div className="radio"></div>
                <div className="radio-label">{type}</div>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
