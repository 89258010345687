import React from 'react';
import { Link } from 'react-router-dom';
import DashboardWrapper from 'components/site/commons/DashboardWrapper';
import requireAuth from 'components/requireAuth';

const HelpPage = () => (
  <div>
    <h1>Awww myan! Really?</h1>

    <p style={{ margin: '1rem 0px 1.5rem' }}>
      No worries! I only created help link because logout button was getting
      lonely up there, you can just simply contact the administrator for any
      issues or help. Cheers!
    </p>

    <Link to="/" className="btn btn-dark">
      Return Home
    </Link>
  </div>
);

export default requireAuth(DashboardWrapper(HelpPage));
