import React, { useState } from 'react';
import PostTripDetailForm from './PostTripDetailForm';
import { editTrip } from 'services/tripService';

export default ({ history, previousTrip }) => {
  const [submitting, setSubmitting] = useState(false);
  const [trip, setTrip] = useState(previousTrip);

  const handleSubmit = () => {
    setSubmitting(true);
    /*
     * Comparing literal values of two different object to check if form has been edited
     */
    const isValueEdited = JSON.stringify(previousTrip) !== JSON.stringify(trip);

    if (isValueEdited) {
      return editTrip(trip).then(({ errors, data }) => {
        if (!errors) return history.push(`/trips/edit/${trip.id}/places`);

        console.error(errors);
        alert('Something went wrong');
        setSubmitting(false);
      });
    }
    return history.push(`/trips/edit/${trip.id}/places`);
  };

  return (
    <PostTripDetailForm
      handleSubmit={handleSubmit}
      submitting={submitting}
      trip={trip}
      setTrip={setTrip}
    />
  );
};
