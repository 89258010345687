import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostTripImageAction from './PostTripImageAction';
import { getTripImages, getTripById } from 'services/tripService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';
import _ from 'lodash';
import './postImage.scss';

/** @Desc
 * Root component for posting trip images i.e. add/delete trip images
 */

const PostTripImagePage = ({ match, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [tripImages, setTripImages] = useState({});
  const [trip, setTrip] = useState(null);

  useEffect(() => {
    const promise1 = getTripById(match.params.id).then(
      ({ errors, data: { data } }) => {
        !errors ? setTrip(data.getTripById) : console.error(errors);
      }
    );

    const promise2 = getTripImages(match.params.id).then(
      ({ errors, data: { data } }) => {
        if (!errors) {
          const images = _.mapValues(
            _.keyBy(data.getTripImages, 'position'),
            'image_url'
          );
          setTripImages(images);
        } else {
          console.error(errors);
        }
      }
    );

    Promise.all([promise1, promise2])
      .then(() => setRenderApp(true))
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    !tripImages ? (
      <OnPageNotFound />
    ) : (
      <PostTripImageAction
        trip={trip}
        tripImages={tripImages}
        setTripImages={setTripImages}
        history={history}
        tripId={match.params.id}
      />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripImagePage));
