import React from 'react';
import { Helmet } from 'react-helmet';
import DashboardWrapper from 'components/site/commons/DashboardWrapper';
import requireAuth from 'components/requireAuth';

const FlushPage = () => (
  <div>
    <Helmet>
      <title>Flush | Visit Nepal Dashboard</title>
    </Helmet>
    flushing...
  </div>
);

export default requireAuth(DashboardWrapper(FlushPage));
