import React, { useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { postTripTypes } from 'services/tripService';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';

export default ({ id, history, previousTripTypes, allTripTypes }) => {
  const [tripTypes, setTripTypes] = useState(previousTripTypes);
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [term, setTerm] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      locale: 'en',
      element: (message) => (
        <div className="validation-message text-danger">{message}</div>
      ),
    })
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function onSubmit() {
    if (validator.allValid()) return handleSubmit();
    validator.showMessages();
    forceUpdate();
  }

  function handleSubmit() {
    setSubmitting(true);
    const isValueEdited =
      JSON.stringify(tripTypes) !== JSON.stringify(previousTripTypes);
    /*
     * https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
     */
    const deepClonedTripTypes = JSON.parse(JSON.stringify(tripTypes));
    if (isValueEdited) {
      return postTripTypes(id, deepClonedTripTypes).then(({ errors, data }) => {
        if (!errors) return history.push(`/trips/edit/${id}/tags`);

        console.error(errors);
        alert('Something went wrong');
        setSubmitting(false);
      });
    }
    return history.push(`/trips/edit/${id}/tags`);
  }

  function onThemeSelection(theme) {
    if (tripTypes.some((t) => t.type === theme.type)) {
      removeTheme(theme);
    } else {
      // add theme
      setTripTypes([...tripTypes, { ...theme, trip_id: id }]);
    }
  }

  function removeTheme(theme) {
    setTripTypes(tripTypes.filter((t) => t.type !== theme.type));
  }

  function handleInputBlur() {
    setTimeout(() => {
      setShowSuggestionList(false);
    }, 500);
  }

  return (
    <div className="container-md postContent" style={{ paddingBottom: '2rem' }}>
      <Helmet>
        <title>Trip themes | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Select theme</h1>
        <p>
          Most often experience have multiple themes, choose all the themes that
          fits your experience. This will help your guest to choose relavant
          experience that suits their needs.
        </p>
        <div className="content-card">
          <div className="d-flex flex-wrap" style={{ margin: '0 -8px' }}>
            {tripTypes.map((t) => (
              <div key={t.type}>
                <div className="s-box" style={{ margin: '8px' }}>
                  <span>{t.type}</span>
                  <button
                    className="btn-chromeless"
                    onClick={() => removeTheme(t)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div style={{ position: 'relative' }}>
            <input
              type="text"
              placeholder="Search for themes"
              className="form-control search-input"
              autoComplete="off"
              name="term"
              onFocus={() => setShowSuggestionList(true)}
              onBlur={handleInputBlur}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
            {validator.message('Theme', tripTypes, 'required|array|min:1')}
            {showSuggestionList && (
              <div className="suggestion-list">
                {allTripTypes
                  .filter((f) =>
                    f.type.toLowerCase().includes(term.toLowerCase())
                  )
                  .map((t) => (
                    <div key={t.type} className="s-item-w">
                      <label className="s-item">
                        <input
                          className="custom-input-checkbox"
                          type="checkbox"
                          name="collection-oncheck"
                          onChange={() => onThemeSelection(t)}
                          checked={tripTypes.some((tt) => tt.type === t.type)}
                          value={t}
                        />
                        <div className="custom-checkbox">
                          <div className="checkbox" />
                          <div className="checkbox-label">{t.type}</div>
                        </div>
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>

        <div className="bottom-panel d-flex">
          <Link to={`/trips/edit/${id}/places`} className="btn btn-default">
            Back
          </Link>
          {submitting ? (
            <div
              className="spi"
              style={{ margin: '12px 0', marginRight: '0' }}
            ></div>
          ) : (
            <button className="btn btn-dark" onClick={onSubmit}>
              Save <i className="fas fa-chevron-right"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
