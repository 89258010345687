import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from 'actions/auth';
import SpinLoader from 'components/site/helpers/SpinLoader';
import './css/style.scss';

/**
  @Desc Root component of our app
  Whenever app loads it checks if user token is present or not
    If present, show loader and get user from the token
    If not present the render app instantly
*/
class App extends Component {
  constructor() {
    super();
    this.state = { renderApp: false };
  }

  componentDidMount() {
    const userToken = localStorage.getItem('token');
    /*
     * Auto login user if token is present
     */
    if (userToken)
      return this.props.getUser(() => this.setState({ renderApp: true }));

    this.setState({ renderApp: true });
  }

  render() {
    return this.state.renderApp ? (
      <div>{this.props.children}</div>
    ) : (
      <h3>
        <SpinLoader />
      </h3>
    );
  }
}

export default connect(null, { getUser })(App);
