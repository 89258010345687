import React from "react";

const notifier = ({ message, icon, style }) => {
  const showNotifier = message ? "show" : "";
  return (
    <div
      style={{ ...style }}
      className={`side-slide-notification d-flex ${showNotifier}`}
    >
      {icon}
      <span>{message}</span>
    </div>
  );
};

export default notifier;
