import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import TripView from '../../TripView';
import { getTripFullDetailById } from 'services/tripService';
import '../../tripView.scss';
import { publishTrip } from 'services/tripService';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';

/** @Desc
 * Root component for publishing trip
 */
const PublishViewPage = ({ id, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [trip, setTrip] = useState(null);

  useEffect(() => {
    getTripFullDetailById(id)
      .then(({ errors, data: { data } }) => {
        !errors ? setTrip(data.getTripById) : console.error(errors);
        setRenderApp(true);
      })
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return renderApp ? (
    <PublishAction history={history} trip={trip} />
  ) : (
    <SpinLoader />
  );
};

export default withRouter(PublishViewPage);

const PublishAction = ({
  history,
  trip,
  trip: { id, includes, to_know, to_bring, trip_types, trip_images, places },
}) => {
  const [error] = useState(
    trip_types.length < 1 ||
      trip_images.length < 1 ||
      places.length < 1 ||
      !includes ||
      !to_know ||
      !to_bring
  );
  const [submitting, setSubmitting] = useState(false);

  function onSubmit() {
    setSubmitting(true);
    publishTrip(id)
      .then(() => {
        setSubmitting(false);
        history.push('/trips');
      })
      .catch(() => setSubmitting(false));
  }

  return (
    <>
      <div
        className="container-md tripview-page"
        style={{ paddingBottom: '2rem' }}
      >
        <Helmet>
          <title>Publish trip | Visit Nepal Dashboard</title>
        </Helmet>

        {error && (
          <div className="error-box d-flex">
            <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true">
              <circle fill="currentColor" cx="10" cy="10" r="9"></circle>
              <path d="M2 10c0-1.846.635-3.543 1.688-4.897l11.209 11.209A7.954 7.954 0 0 1 10 18c-4.411 0-8-3.589-8-8m14.312 4.897L5.103 3.688A7.954 7.954 0 0 1 10 2c4.411 0 8 3.589 8 8a7.952 7.952 0 0 1-1.688 4.897M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10"></path>
            </svg>
            Looks like one or more fields are yet to be filled, please fill up
            all the fields and come back
          </div>
        )}
        <TripView trip={trip} />
        <hr style={{ marginBottom: '2.5rem' }} />
        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
          <Link to={`/trips`} className="btn btn-default">
            Exit
          </Link>

          {submitting ? (
            <div
              className="spi"
              style={{ margin: '12px 0', marginRight: '0' }}
            ></div>
          ) : (
            <button
              onClick={() => onSubmit()}
              className="btn btn-primary"
              disabled={error}
            >
              Publish
            </button>
          )}
        </div>
      </div>
    </>
  );
};
