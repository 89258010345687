import React, { useState } from 'react';
import PostForm from './PostForm';
import { addTrip, editTrip } from 'services/tripService';
import { withRouter } from 'react-router-dom';

const PostActions = ({ editFormProps, history }) => {
  const [formProps, setFormProps] = useState(
    editFormProps
      ? editFormProps
      : {
          name: '',
          enabled: true,
          featured: false,
          price: 0,
          duration: '',
          activity_level: 'Easy',
          best_season: '',
          max_altitude: '',
          description: '',
        }
  );

  const [submitting, setSubmitting] = useState(false);

  const updateForm = ({ target }) => {
    let { name, value } = target;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      default:
    }
    setFormProps({ ...formProps, [name]: value });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    editFormProps ? handleTripEdit() : handleTripAdd();
  };

  const handleTripEdit = () => {
    /*
     * Comparing literal values of two different object to check if form has been edited
     */
    const isValueEdited =
      JSON.stringify(editFormProps) !== JSON.stringify(formProps);

    if (isValueEdited) {
      return editTrip(formProps).then(({ errors, data }) => {
        if (!errors) return history.push(`/trips/edit/${formProps.id}/details`);

        console.error(errors);
        alert('Something went wrong');
        setSubmitting(false);
      });
    }
    return history.push(`/trips/edit/${formProps.id}/details`);
  };

  const handleTripAdd = () => {
    addTrip(formProps).then(({ errors, data: { data } }) => {
      // IF no errors redirect to image upload page
      if (!errors)
        return history.push(`/trips/edit/${data.addTrip.id}/details`);

      console.error(errors);
      alert('Something went wrong');
      setSubmitting(false);
    });
  };

  return (
    <PostForm
      actions={{ handleSubmit, updateForm }}
      states={{
        formProps,
        submitting,
        isEdit: editFormProps ? true : false,
      }}
    />
  );
};

export default withRouter(PostActions);
