import React, { useState, useEffect } from 'react';
import SpinLoader from 'components/site/helpers/SpinLoader';
import PostTripPlaceAction from './PostTripPlaceAction';
import { getTripPlaces } from 'services/tripService';
import { getAllPlaces } from 'services/placeService';
import requireAuth from 'components/requireAuth';
import SideNav from '../SideNav';
import { OnPageNotFound } from 'components/site/misc/NotFoundPage';
import 'components/css/post.scss';

/** @Desc
 * Root component for adding/deleting trip places i.e. Nepal, kathmandu, nagarkot etc
 */
const PostTripTypePage = ({ match, history }) => {
  const [renderApp, setRenderApp] = useState(false);
  const [tripPlaces, setTripPlaces] = useState([]);
  const [allPlaces, setAllPlaces] = useState([]);

  useEffect(() => {
    const promise1 = getTripPlaces(match.params.id).then(
      ({ errors, data: { data } }) => {
        !errors
          ? setTripPlaces(data.getTripById.places)
          : console.error(errors);
      }
    );

    const promise2 = getAllPlaces().then(({ errors, data: { data } }) => {
      !errors ? setAllPlaces(data.getAllPlaces) : console.error(errors);
    });

    Promise.all([promise1, promise2])
      .then(() => setRenderApp(true))
      .catch(() => setRenderApp(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return renderApp ? (
    !tripPlaces ? (
      <OnPageNotFound />
    ) : (
      <PostTripPlaceAction
        history={history}
        previousTripPlaces={tripPlaces}
        allPlaces={allPlaces}
        id={match.params.id}
      />
    )
  ) : (
    <SpinLoader />
  );
};

export default requireAuth(SideNav(PostTripTypePage));
