import React from 'react';
import { NavLink } from 'react-router-dom';
import TopNav from './TopNav';
import './dashboardWrapper.scss';

export default (ChildComponent) => (props) => (
  <div id="mjlApp">
    <nav className="nav-right">
      <div className="nav-brand d-flex">
        <div className="img-wrap">
          <img src="/assets/images/logo.svg" alt="logo" />
        </div>
      </div>
      <ul className="nav-links neutralize">
        <li>
          <NavLink to="/" exact={true}>
            <div className="s-icon">
              <i className="fas fa-home"></i>
            </div>{' '}
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/places">
            <div className="s-icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>{' '}
            Places
          </NavLink>
        </li>
        <li>
          <NavLink to="/trips">
            <div className="s-icon">
              <i className="fas fa-route"></i>
            </div>{' '}
            Trips
          </NavLink>
        </li>
        <li>
          <NavLink to="/customers">
            <div className="s-icon">
              <i className="fas fa-users"></i>
            </div>{' '}
            Customers
          </NavLink>
        </li>

        {/*
          <li>
            <NavLink to="/analytics/sales">
              <div className="s-icon">
                <i className="fas fa-chart-area"></i>
              </div>{" "}
              Analytics
            </NavLink>
            <div className="dropdown">
              <ul className="neutralize">
                <li>
                  <NavLink to="/analytics/sales">Sales</NavLink>
                </li>
                <li>
                  <NavLink to="/analytics/demographics">Demographics</NavLink>
                </li>
              </ul>
            </div>
          </li>
          */}
      </ul>
    </nav>
    <div className="_main_panel">
      <TopNav />
      <div className="_bod_wrap">
        <div className="_bod_content">
          <ChildComponent {...props} />
        </div>
      </div>
    </div>
  </div>
);
