import React, { useState } from 'react';
import ImageList from './ImageList';
import _ from 'lodash';
import { uploadTripImage } from 'services/tripService';

export default ({
  deleteImage,
  updateImagePosition,
  setTripImages,
  tripImages,
  tripId,
}) => {
  const [errors, setErrors] = useState([]);

  /**
  * @Desc Is called by ImageList component when product images is added. Validates and adds
    uploads images to server and update images state array
  * @Params acceptedFiles is an array of accepted images, rejectedFiles is an array of other file which are not images
    enabledSorting function enabled images sorting after upload is completed
    disableSorting function disables images sorting while images is getting uploaded
  */
  const onImageDrop = (
    acceptedFiles,
    rejectedFiles,
    enableSorting,
    disableSorting
  ) => {
    /*
     * Removing previous errors
     */
    setErrors([]);

    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach((file) => {
        setErrors([
          ...errors,
          `${file.path} is not supported image type. Upload files ending in .jpg or .png`,
        ]);
      });
    } else {
      disableSorting();

      /*
        * After reaching inside loop updating react state won't have real time effect inside Loop
          as react gives out state reference as such condition
       * Using temprary uploadedImages to hold all trip images
       */
      let uploadedImages = { ...tripImages };
      const currentImageLength = Object.keys(uploadedImages).length;
      const imageErrors = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        if (currentImageLength + i < 5) {
          const file = acceptedFiles[i];
          if (file.size > 1000000) {
            imageErrors.push(
              `${file.path} is too big, upload image of less than 1MB`
            );
          } else {
            const position = _.size(uploadedImages) + 1;
            /* Adding uploaded image data to uploadedImages */
            uploadedImages = { ...uploadedImages, [position]: null };
            handleProductImage(file, position).then(({ data }) =>
              onImageUpload(data, position)
            );
          }
        } else {
          setErrors([...errors, `You can upload upto 5 images only`]);
          break;
        }
      }
      /*
       * A work around for stale react reference inside loop
       */
      if (imageErrors.length > 0) setErrors([...errors, ...imageErrors]);
      /**
      * @Desc A callback called after the image upload request to the server is completed
      * @Params
        data: String - image source returned from the server | image_url
        position: position assigned to the uploaded image
      */
      const onImageUpload = (data, position) => {
        uploadedImages = { ...uploadedImages, [position]: data };
        setTripImages({ ...uploadedImages, [position]: data });
        enableSorting();
      };
      setTripImages({ ...tripImages, ...uploadedImages });
    }
  };

  /**
   * @Desc Handles product image upload to server
   * @returns Promise with response from server
   */
  function handleProductImage(image, position) {
    let formData = new FormData();
    formData.append('image', image);
    formData.append('position', position);
    return uploadTripImage(formData, tripId);
  }

  /**
  * @Desc Called each time a image is sorted
    @Params object with oldIndex and newIndex properties
    oldIndex is sorted image previous position and newIndex is sorted image new position
  */
  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    if (oldIndex !== newIndex) updateImagePosition(oldIndex, newIndex);

    /*
     * fixing small bug that shows the remove button when the item to be sorted gets hovered over another item
     */
    const removeBtns = e.target.getElementsByClassName('btn-wrap');
    for (var i = 0; i < removeBtns.length; i++) {
      removeBtns[i].classList.remove('d-none');
    }
  };

  /*
   * fixing small bug that shows the remove button when the item to be sorted gets hovered over another item
   */
  const onSortMove = (e) => {
    const removeBtns = e.target.getElementsByClassName('btn-wrap');
    for (var i = 0; i < removeBtns.length; i++) {
      removeBtns[i].classList.add('d-none');
    }
  };

  return (
    <div>
      {errors.length > 0 && <RenderError errors={errors} />}
      <div>
        <div>
          <ImageList
            axis="xy"
            onSortMove={onSortMove}
            onSortEnd={onSortEnd}
            onImageDrop={onImageDrop}
            deleteImage={deleteImage}
            tripImages={tripImages}
          />
        </div>
      </div>
    </div>
  );
};

function RenderError({ errors }) {
  return (
    <div className="col err-wr">
      <div>
        <div className="iw">
          <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true">
            <circle fill="currentColor" cx="10" cy="10" r="9"></circle>
            <path d="M2 10c0-1.846.635-3.543 1.688-4.897l11.209 11.209A7.954 7.954 0 0 1 10 18c-4.411 0-8-3.589-8-8m14.312 4.897L5.103 3.688A7.954 7.954 0 0 1 10 2c4.411 0 8 3.589 8 8a7.952 7.952 0 0 1-1.688 4.897M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10"></path>
          </svg>
        </div>
      </div>
      <div>
        <p>These images couldn’t be added:</p>
        <ul>
          {errors.map((err, i) => (
            <li key={i}>{err}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
