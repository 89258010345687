import React, { Component } from "react";
import { connect } from "react-redux";
import NotFoundPage from "./site/misc/NotFoundPage";

// ChildComponent is the component that's passed as a arguement into this function
export default ChildComponent => {
  class ComposedComponent extends Component {
    render() {
      if (this.props.auth) {
        return <ChildComponent {...this.props} />;
      } else {
        return <NotFoundPage />;
      }
    }
  }
  function mapStateToProps({ auth }) {
    return { auth };
  }
  return connect(mapStateToProps)(ComposedComponent);
};
