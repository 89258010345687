import React, { useState, useReducer, useEffect } from 'react';
import Modal from 'components/site/helpers/Modal';
import SimpleReactValidator from 'simple-react-validator';
import TextAreaAutoSize from 'components/site/helpers/TextAreaAutoSize';
import Dropzone from 'react-dropzone';
import ConfirmBox from 'components/site/helpers/ConfirmBox';
import SlideNotifier from 'components/site/helpers/SlideNotifier';
import {
  postItinerary,
  deleteItineraryImage,
  deleteItinerary,
} from 'services/tripService';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

/**
 * @Desc main logic component for handling trip itinerary
 * @Params
    tripItineraries : array - list of itineries
    reFetchItineraries : function - use to refetch new updated itenary list
    it: trip id
 */
export default ({ tripItineraries, reFetchItineraries, id }) => {
  const newItinerary = {
    id: '',
    day: tripItineraries.length + 1,
    title: '',
    description: '',
    image_url: null,
    trip_id: id,
  };
  /* Modal for showing itinerary form */
  const [showModal, setShowModal] = useState(false);
  /*
   * Selected itinery to show on the modal
   * If user click on add new then new itinery blank form will be initialized
   * If user clicks on existing itinery then that itinery will be loading in the modal form
   */
  const [selectedItinerary, setSelectedItinerary] = useState(newItinerary);

  /*
   * Function called when modal is closed
   * setModal(false) closes the modal
   * reFetchItineraries - will re-fetch the itineries and updates the itinery list
   */
  function closeModal() {
    setShowModal(false);
    reFetchItineraries();
  }

  return (
    <div className="container-md postContent" style={{ paddingBottom: '2rem' }}>
      <Helmet>
        <title>Trip itinerary | Visit Nepal Dashboard</title>
      </Helmet>
      <div className="row">
        <h1 className="page-title">Add itinerary</h1>
        <p>
          Add day by day plans to your trip or experience. Summarize what you'll
          be doing and add a special relevant picture. You can skip this section
          if your experience has no itinerary.
        </p>
        <div className="content-card">
          {tripItineraries.map((i) => (
            <div key={i.id}>
              <button
                className="itinerary-btn btn-chromeless"
                onClick={() => {
                  setSelectedItinerary(i);
                  setShowModal(true);
                }}
              >
                <i className="fas fa-pencil-alt"></i>
                Day {i.day}
              </button>
            </div>
          ))}
          <button
            style={{ marginBottom: 0 }}
            className="itinerary-btn btn-chromeless"
            onClick={() => {
              setSelectedItinerary(newItinerary);
              setShowModal(true);
            }}
          >
            <i className="text-success fas fa-plus-circle"></i>
            Add day
          </button>
        </div>
      </div>
      {showModal && (
        <Modal
          backgroundColor="#FFF"
          overlayColor="#FFF"
          noShadow={true}
          showCloseButton={true}
          onClose={closeModal}
        >
          <Itinerary
            closeModal={closeModal}
            selectedItinerary={selectedItinerary}
          />
        </Modal>
      )}

      <div className="bottom-panel d-flex">
        <Link to={`/trips/edit/${id}/tags`} className="btn btn-default">
          Back
        </Link>
        <Link to={`/trips/edit/${id}/photos`} className="btn btn-dark">
          Next <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </div>
  );
};

/**
* @Desc Itinerary form for creating new itinery or updating existing one
* @params
  closeModal : function - callback function when modal is closed
  selectedItinerary : object - selected itinery object to initialize form
*/
const Itinerary = ({ closeModal, selectedItinerary }) => {
  const [itinerary, setItinerary] = useState(selectedItinerary);
  /* Holds image file when new image is uploaded  */
  const [image, setImage] = useState(null);
  /*
   * To show loader when requesting to server
   */
  const [submitting, setSubmitting] = useState(false);
  const [itineraryDeleting, setItineraryDeleting] = useState(false);

  const { id, day, title, description, image_url, trip_id } = itinerary;

  /* Form validator */
  const [validator] = useState(
    new SimpleReactValidator({
      locale: 'en',
      element: (message) => (
        <div className="validation-message text-danger">{message}</div>
      ),
    })
  );
  /* Form validator needs to force update component for showing error message */
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  /*
   * Function that handles form submit
   * Displays error messages if any errors
   * Invoke handleSubmit function is no errors
   */
  function onSubmit() {
    if (validator.allValid()) return handleSubmit();
    validator.showMessages();
    forceUpdate();
  }

  /*
   * Handles form data and uploads to server
   * Append all properties to form data | form data is used for handling image file
      Invoke closeModal function upon success
   */
  function handleSubmit() {
    setSubmitting(true);
    let formData = new FormData();
    formData.append('id', id);
    formData.append('title', title);
    formData.append('image', image);
    formData.append('day', day);
    formData.append('description', description);
    formData.append('trip_id', trip_id);
    postItinerary(formData)
      .then(() => {
        setSubmitting(false);
        closeModal();
      })
      .catch(() => setSubmitting(false));
  }

  /*
   * Generic function called when handling form input
   */
  const updateForm = ({ target }) => {
    let { name, value } = target;
    setItinerary({ ...itinerary, [name]: value });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="form-group">
        <label className="label">Day*</label>
        <div className="input-group">
          <div className="input-group-prepend">Day</div>
          <input
            onChange={updateForm}
            autoComplete="off"
            value={day}
            type="number"
            name="day"
            className="form-control"
          />
        </div>
        {validator.message('day', day, 'required|numeric|min:1,num')}
      </div>

      <div className="form-group">
        <label className="label">Title*</label>
        <input
          value={title}
          name="title"
          placeholder="e.g. Arrival in Kathmandu"
          maxLength="100"
          autoComplete="off"
          className="form-control"
          onChange={updateForm}
        />
        {validator.message('title', title, 'required|max:100')}
      </div>

      <div className="form-group">
        <label className="label">What we'll be doing</label>
        <TextAreaAutoSize
          name="description"
          value={description}
          maxLength="550"
          placeholder="Write the activities for the day"
          onChange={updateForm}
          className="form-control"
        />
        <div style={{ marginTop: '4px' }}>{description.length} / 550</div>
        {validator.message('description', description, 'max:550')}
      </div>

      <div className="form-group">
        <ItineraryImage
          itineraryId={id}
          setImage={setImage}
          image_url={image_url}
        />
      </div>

      {id && (
        <div className="clearfix">
          <ConfirmBox
            onConfirm={() => {
              setItineraryDeleting(true);
              deleteItinerary(id)
                .then(() => {
                  closeModal();
                })
                .catch(() => closeModal());
            }}
            caption="This will delete your itinerary"
            btnClass="btn-chromeless delete-itinerary"
            label="Yes, Delete it!"
          >
            <span className="text-danger">Delete itinerary</span>
          </ConfirmBox>
        </div>
      )}

      <hr style={{ marginBottom: '20px' }} />
      {submitting ? (
        <div className="spi" style={{ marginLeft: 0 }} />
      ) : (
        <button className="btn btn-dark" onClick={onSubmit}>
          Save
        </button>
      )}

      {itineraryDeleting && (
        <div className="mrn-overlay">
          <div className="spi" />
        </div>
      )}
    </div>
  );
};

/**
* @Desc Handles image input and deletion
* @Params
  itineraryId: int - itenary id | empty if new itinery
  image_url: String - itinery image_url | empty if new itinery
  setImage function - function that is used to store user input image
*/
const ItineraryImage = ({ itineraryId, image_url, setImage }) => {
  /*
   * url that is used for image display. initialize with parent image_url
   * Will later get changed to temporary blob image source if user uploads new image
   */
  const [imageUrl, setImageUrl] = useState(image_url);
  /* Show error message if validation fails */
  const [message, setMessage] = useState(null);
  const [dropzone, setDropzone] = useState({
    disable: false,
    hovering: false,
  });

  /*
   * Handling dropzone state i.e. hover and disable
   */
  useEffect(() => {
    setDropzone({ ...dropzone, disable: imageUrl ? true : false });
  }, [imageUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   Function for handling user uploaded image
   * Will show error
    if rejected file array exist
    if acceptedFiles is not found
    something else
  * Will pass validation if
    acceptedFiles exist
    Accepted file size is not more than 1MB
  */
  const handleOnDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setMessage('Only single picture allowed');
      setTimeout(() => setMessage(null), 3000);
    } else if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size > 1000000) {
        // If image is greater then 1MB
        setMessage('Image size is too big');
        setTimeout(() => setMessage(null), 3000);
      } else {
        const imageFile = acceptedFiles[0];
        handleImageUpload(imageFile);
      }
    } else {
      setMessage('Something went wrong');
      setTimeout(() => setMessage(null), 3000);
    }
  };

  /*
   * setImageUrl function will set the uploaded image blob source
   * setImage function will store the image file
   */
  const handleImageUpload = (image) => {
    setImageUrl(URL.createObjectURL(image));
    setImage(image);
  };

  /*
  * Handles image deleting
  * When image delete button is click
  * If itineryId exist - if itinery is not new
    Delete itinery image using itinery id
    Set image source to null
    set image to null
  */
  const handleImageDelete = (image) => {
    if (itineraryId) deleteItineraryImage(itineraryId);
    setImageUrl(null);
    setImage(null);
  };

  const dropZoneClass = dropzone.hovering
    ? `place-dropzone active`
    : 'place-dropzone';
  return (
    <Dropzone
      disabled={dropzone.disable}
      accept="image/png,image/jpeg"
      onDrop={handleOnDrop}
      multiple={false}
      onDragEnter={() => {
        setDropzone({ ...dropzone, dropHovered: true });
      }}
      onDragLeave={() => {
        setDropzone({ ...dropzone, dropHovered: false });
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={dropZoneClass}>
          <input {...getInputProps()} />
          {imageUrl ? (
            <RenderImage
              image_url={imageUrl}
              onImageDelete={handleImageDelete}
            />
          ) : (
            <div className="caption">
              <svg
                viewBox="0 0 24 24"
                role="img"
                aria-label="Add photo"
                focusable="false"
              >
                <path
                  d="m18.5 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0-4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm4-4h-21c-.83 0-1.5.67-1.5 1.5v17.01c0 .82.68 1.49 1.5 1.49h20.99c.84 0 1.5-.67 1.5-1.5v-17c .01-.83-.66-1.5-1.49-1.5zm-21.5 1.5c0-.28.22-.5.5-.5h20.99c.28 0 .5.23.5.5v13.8l-5.15-5.15a.5.5 0 0 0 -.65-.05l-3.65 2.74-4.69-4.69a.5.5 0 0 0 -.71 0l-7.14 7.14zm22 17.01a.49.49 0 0 1 -.5.5h-21a .5.5 0 0 1 -.5-.5v-1.8l7.5-7.5 4.65 4.65a.5.5 0 0 0 .65.05l3.65-2.74 5.55 5.54z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </div>
          )}

          <SlideNotifier
            style={{
              backgroundColor: '#a94442',
              position: 'absolute',
            }}
            icon={
              <svg className="svg-icon" viewBox="0 0 20 20">
                <path
                  fill="none"
                  d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                ></path>
              </svg>
            }
            message={message}
          />
        </div>
      )}
    </Dropzone>
  );
};

const RenderImage = ({ image_url, onImageDelete }) => (
  <div className="image-panel">
    <ConfirmBox
      label="Yes, Delete it!"
      caption="Once deleted, you won't be able to retrieve same image"
      btnClass="btn-chromeless"
      onConfirm={onImageDelete}
    >
      <svg className="svg-icon" viewBox="0 0 20 20">
        <path
          fill="none"
          d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
        ></path>
      </svg>
    </ConfirmBox>
    <img src={image_url} alt="itenary" />
  </div>
);
